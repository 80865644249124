import api from "@api";

// export const api_getAllTeacher = (param) => {
//     console.log("sslfjd: ", param);
//     const url = `/teacher`;
//     return api.get(url, {
//         params: {
//             _page: param.page,
//             _filter: param.filters,
//         },
//     });
// };

export const api_createTeacher = (param) => {
    const url = `teacher`;
    return api.post(url, param);
};

export const api_getOneTeacher = (param) => {
    const url = `teacher/${param}`;
    return api.get(url, {
        params: {
            _relations: "courses",
        },
    });
};

export const api_updateTeacher = (param) => {
    const url = `teacher/${param.id}`;
    return api.put(url, param.data);
};
