import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import modal from "./modal";
import acl from "./acl";
import branch from "./branch";

export default combineReducers({
    auth,
    menu,
    modal,
    acl,
    branch,
});
