import React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "@api";
import jwtDecode from "jwt-decode";
import Config from "@config";
import { useComponentWillMount } from "./index";

const validate = function (history) {
    const isLoggedIn = !!window.localStorage.getItem("token");
    if (!isLoggedIn && history.location.pathname != "/login") {
        history.replace("/login");
    }
    axios.defaults.headers.common["Authorization"] = "Bearer " + window.localStorage.getItem("token");
    if (isLoggedIn && (history.location.pathname == "/login" || history.location.pathname == "/")) {
        history.replace("/dashboard");
        // if (
        //     getRole().notify === undefined &&
        //     getRole().statistic &&
        //     getRole().statistic.view_menu &&
        //     getRole().statistic.read
        // ) {
        //     history.replace("/dashboard");
        // } else {
        //     history.replace("/dashboard");
        // }
        // if (getRole() == 'DEVELOPER') {
        //   history.replace("/account");
        // }
        // else if (getRole() == 'BANKER' || getRole() == 'ACCOUNTANT') {
        //   history.replace("/statistic");
        // }
        // else if (getRole() == 'COLLABORATOR') {
        //   history.replace("/lead");
        // }
        // else {
        //   history.replace("/home");
        // }
    }
};

/**
 * Higher-order component (HOC) to wrap restricted pages
 */
// export default function authHOC(BaseComponent) {
//     class Restricted extends Component {
//         UNSAFE_componentWillMount() {
//             this.checkAuthentication(this.props);
//         }
//         UNSAFE_componentWillReceiveProps(nextProps) {
//             if (nextProps.location !== this.props.location) {
//                 this.checkAuthentication(nextProps);
//             }
//         }
//         checkAuthentication(params) {
//             const { history } = params;
//             validate(history);
//         }
//         render() {
//             return <BaseComponent {...this.props} />;
//         }
//     }
//     return withRouter(Restricted);
// }

export default function authHOC(BaseComponent) {
    function Restricted(props) {
        useComponentWillMount(() => checkAuthentication(props));

        useEffect(() => {
            checkAuthentication(props);
        }, [props.location]);

        function checkAuthentication(params) {
            const { history } = params;
            validate(history);
        }
        return <BaseComponent {...props} />;
    }
    return withRouter(Restricted);
}

/**
 * Store token
 */
export function storeToken(token, user = null) {
    return new Promise((resolve) => {
        // if (user != null) {
        //   window.localStorage.setItem('user', toJson(user));
        // }
        window.localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return resolve(true);
    });
}

/**
 * Remove token
 */
export function removeToken() {
    window.localStorage.removeItem("token");
    // window.location.href = "/login";
}

/**
 * Validate role
 */
export function checkRole(user_role) {
    let acl = localStorage.getItem("acl");
    if (acl) {
        let acl_decoded = jwtDecode(acl);
        var exists = false;
        for (var j = 0; j < acl_decoded.length; j++) {
            if (user_role.indexOf(acl_decoded[j]) !== -1) {
                exists = true;
            }
        }
        if (!exists) {
            return false;
        } else {
            return true;
        }
    }

    return false;
}

export function getRole(acl) {
    if (localStorage.getItem("roles")) {
        localStorage.removeItem("roles");
        localStorage.removeItem("token");
        window.location.reload();
    }
    if (!acl) {
        acl = localStorage.getItem("acl");
    }
    if (acl) {
        let acl_decoded = jwtDecode(acl);
        return acl_decoded;
    }
    return [];
}

export function getUserInfo() {
    if (localStorage.getItem("user")) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
    }
    let token = localStorage.getItem("token");
    if (token) {
        let token_decoded = jwtDecode(token);
        return token_decoded.user;
    }

    return {};
}

export function getTimeOutToRefreshToken() {
    let token = localStorage.getItem("token");

    if (token) {
        let token_decoded = jwtDecode(token);
        let time = (token_decoded.timeout - 300 - new Date().getTime() / 1000) * 1000;

        if (parseInt(time) < 86400000) {
            setTimeout(() => {
                axios
                    .post(
                        Config.BASE_URL + "/user/refresh",
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then(function (res) {
                        storeToken(res.token);
                    });
            }, parseInt(time));
        }
    }
}
