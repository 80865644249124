import React, { useState, useEffect } from "react";
import { _checkExistFilter, useComponentWillMount } from "@utils";
import { numberWithCommas } from "@utils";
import { Row, Col, Button, Input, Select, Checkbox, Pagination, Collapse, Spin } from "antd";
import { getRole } from "@utils/auth";
import FilterForMobile from "@components/FilterForMobile";
import { isEmpty } from "lodash";
import "./index.scss";

let { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;

export default function IndexForMobile(p) {
    const {
        fetch,
        handleSearch,
        loadingConfirm,
        selectedRowKeys,
        onLoading,
        pagination,
        loading,
        data,
        columns,
        filterData,
        setFilterData,
        setSelectedRowKeys,
        setIndeterminate,
        setCheckAll,
    } = p;

    // useComponentWillMount(() => {
    //     if (!p.isLargeScreen) {
    //         p.resetConfigColumns();
    //     }
    // });

    const headerForCollapse = columns.filter((item) => item.dataIndex === "name" || item.dataIndex === "status");

    const _onChange = (e) => {
        let checkedList = "";
        let checkExist = "";
        if (selectedRowKeys && selectedRowKeys.length !== 0) {
            checkExist = selectedRowKeys.filter((item) => item === e.target.value.id);
        }
        if (checkExist.length !== 0) {
            checkedList = selectedRowKeys.filter((item) => item !== e.target.value.id);
        } else {
            checkedList = [...selectedRowKeys, e.target.value.id];
        }
        setSelectedRowKeys(checkedList);
    };

    const _onCheckAllChange = (e) => {
        let checkedList = [];
        if (e.target.checked) {
            data.length !== 0 && data.map((item, index) => checkedList.push(item.id));
        }
        setSelectedRowKeys(e.target.checked ? checkedList : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const _handlePaginationChange = (page) => {
        fetch(page, null, filterData);
    };

    const _clearFilterForMobile = (type) => {
        const check = { ...filterData };
        delete check[type];
        setFilterData(check, (s) => fetch(null, null, s));
    };

    const _handleFilter = (value, type) => {
        let filterQuery = { ...filterData };
        if (value === "ALL") {
            _clearFilterForMobile(type);
        } else {
            if (type === "status") {
                filterQuery.status = value;
            }

            setFilterData(filterQuery);
            fetch(1, null, filterQuery);
        }
    };

    const _renderListInvoice = () => {
        if (columns[1]?.title != "Hành động" && data.length !== 0 && columns.length !== 0) {
            return (
                data &&
                data.map((item, index) => (
                    <Panel
                        key={index}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        className="item-card"
                        header={
                            <React.Fragment>
                                {headerForCollapse.length !== 0 ? (
                                    headerForCollapse.map((itemHeader, indexHeader) => (
                                        <div key={indexHeader} className="student-info">
                                            <b>{itemHeader.title}</b>:{" "}
                                            {itemHeader.render
                                                ? itemHeader.render(item.dataIndex, item)
                                                : item[itemHeader.dataIndex]}
                                        </div>
                                    ))
                                ) : (
                                    <React.Fragment>
                                        <b>{columns[1].title && columns[1].title}</b>:
                                        {item[columns[1].dataIndex && columns[1].dataIndex]}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                    >
                        {columns.map((subItem, subIndex) => (
                            <div key={subIndex}>
                                {subItem.dataIndex !== "name" ? (
                                    subItem.dataIndex !== "status" ? (
                                        <React.Fragment>
                                            <b>{subItem.title}</b>:{" "}
                                            {subItem.render
                                                ? subItem.render(item[subItem.dataIndex], item, index)
                                                : item[subItem.dataIndex]}
                                        </React.Fragment>
                                    ) : null
                                ) : null}
                            </div>
                        ))}
                    </Panel>
                ))
            );
        } else {
            if (!loading) {
                return <h1 style={{ textAlign: "center" }}>Không có dữ liệu</h1>;
            }
        }
    };

    return (
        <React.Fragment>
            <Row className="pne-controll-response" type="flex" justify="space-between">
                <Col span={24}>
                    <Search className="pne-controll-response__search" placeholder="Tìm kiếm" onSearch={handleSearch} />
                </Col>
                <Col span={24}>
                    <Button
                        className="pne-controll-response__button"
                        type="primary"
                        onClick={() => console.log("sdsad")}
                        loading={loadingConfirm}
                    >
                        Thêm hoá đơn
                    </Button>
                </Col>
            </Row>
            <Row type="flex" justify="center">
                <Col span={24}>
                    <div className="list-item">
                        <div className="filter-select-title">Trạng thái: </div>
                        <FilterForMobile
                            type="status"
                            filters={[
                                { text: "Chưa thanh toán", value: 1 },
                                { text: "Đã thanh toán", value: 2 },
                            ]}
                            _handleFilter={_handleFilter}
                            _clearFilterForMobile={_clearFilterForMobile}
                        />
                        <React.Fragment>
                            {loading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Spin />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Collapse
                                        expandIconPosition="right"
                                        style={{
                                            border: "none",
                                            backgroundColor: "inherit",
                                        }}
                                    >
                                        {_renderListInvoice()}
                                    </Collapse>
                                    {data.length !== 0 && !isEmpty(pagination) && (
                                        <Pagination
                                            onChange={_handlePaginationChange}
                                            className="pagination"
                                            defaultCurrent={pagination.current}
                                            total={pagination.total}
                                            defaultPageSize={pagination.pageSize}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}
