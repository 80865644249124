import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Select, TimePicker, Row, Col, message, Tabs } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useLocation } from "react-router-dom";

import CenterArea from "@components/CenterArea";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { validateObject, numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { api_createPitch, api_getOnePitch, api_updatePitch } from "./apiPitch";
import { api_getAllTeacher, api_getAllBranchOptions } from "@api/globalApi";
import { getRole, getUserInfo } from "@utils/auth";
import { InputCurrency } from "@components/Input";

const { TabPane } = Tabs;
const { Option } = Select;

const Detail = (props) => {
    const { history } = props;
    let { pathname } = useLocation();
    const [form] = NewForm.useForm();
    let user = getUserInfo();
    const acl = useSelector((state) => state.acl.acl);

    // const branchRedux = useSelector((state) => state.branch.branch_id);
    const [schedule, setSchedule] = useState({
        day_1: [{ start_time: null, end_time: null, tuition: 0 }],
        day_2: [{ start_time: null, end_time: null, tuition: 0 }],
        day_3: [{ start_time: null, end_time: null, tuition: 0 }],
        day_4: [{ start_time: null, end_time: null, tuition: 0 }],
        day_5: [{ start_time: null, end_time: null, tuition: 0 }],
        day_6: [{ start_time: null, end_time: null, tuition: 0 }],
        day_7: [{ start_time: null, end_time: null, tuition: 0 }],
    });
    const [listBranch, setBranch] = useState([]);
    const { id } = props.match.params;
    const onFinish = async (values) => {
        let dataSubmit = createSubmitData(values);
        console.log("dataSubmit: ", dataSubmit);
        if (id) {
            const res = await api_updatePitch({
                id,
                data: { ...dataSubmit },
            });
            if (res.status) {
                message.success("Cập nhật thành công");
                history.push("/pitch");
            } else {
                message.error("Cập nhật thất bại, vui lòng thử lại sau");
            }
        } else {
            const res = await api_createPitch({
                ...dataSubmit,
            });
            if (res.status) {
                message.success("Tạo thành công");
                history.push("/pitch");
            } else {
                message.error("Tạo thất bại, vui lòng thử lại sau");
            }
        }
    };
    useEffect(() => {
        if (id) {
            _getPitch();
        } else {
            form.setFieldsValue({
                status: 1,
                gender: 1,
                branch_id: user.branch_id,
                duration: 8,
            });
        }
        const _getAllBranch = async () => {
            let params = { params: { _orderBy: "id:asc" } };
            const res = await api_getAllBranchOptions(params);
            if (res.status) {
                if (res.data) {
                    setBranch(toArray(res.data));
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        return () => {
            if (!history.location.pathname.includes("/pitch")) {
                sessionStorage.clear();
            }
        };
    }, []);
    const _getPitch = async () => {
        const res = await api_getOnePitch(id);
        if (res.status) {
            const { name, note, branch_id } = res.data;
            form.setFieldsValue({
                name,
                note,
                branch_id,
            });
            const arr = ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"];
            let newSchedule = {};
            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < res.data[arr[i]].length; j++) {
                    res.data[arr[i]][j].tuition = numberWithoutCommas(res.data[arr[i]][j].tuition);
                }
                newSchedule[arr[i]] = res.data[arr[i]];
            }
            setSchedule(newSchedule);
        }
    };

    const createSubmitData = (values) => {
        let data = validateObject(values);
        let newData = { ...data, ...schedule };

        // data.tuition = numberWithoutCommas(data.tuition);

        return newData;
    };

    const _renderSchedule = (list, day) => {
        return (
            list &&
            list.map((item, index) => (
                <Row
                    style={{ marginBottom: "10px" }}
                    key={index}
                    gutter={{
                        xs: 8,
                        sm: 8,
                        md: 16,
                    }}
                >
                    <Col span={7}>
                        <TimePicker
                            onChange={(time, timeString) => _onChange(day, index, "start_time", timeString)}
                            name="start_time"
                            placeholder="Chọn thời gian"
                            value={item.start_time && moment(item.start_time, "HH:mm")}
                            format="HH:mm"
                        />
                    </Col>
                    <Col span={7}>
                        <TimePicker
                            onChange={(time, timeString) => _onChange(day, index, "end_time", timeString)}
                            name="end_time"
                            placeholder="Chọn thời gian"
                            value={item.end_time && moment(item.end_time, "HH:mm")}
                            format="HH:mm"
                        />
                    </Col>
                    <Col span={7}>
                        <InputCurrency
                            onChange={(e) => _onChange(day, index, "tuition", e.target.value)}
                            value={numberWithCommas(item.tuition)}
                            name="tuition"
                            placeholder="Tiền thuê"
                            addonAfter="VNĐ"
                        />
                    </Col>
                    <Col span={3}>
                        <Button onClick={() => _deleteSchedule(day, index)} shape="circle" icon={<MinusOutlined />} />
                    </Col>
                </Row>
            ))
        );
    };

    const _deleteSchedule = (day, position) => {
        let newSchedule = { ...schedule };
        newSchedule[day].splice(position, 1);
        setSchedule(newSchedule);
    };

    const _addSchedule = (day) => {
        let item = { start_time: "", end_time: "", tuition: 0 };
        let newSchedule = { ...schedule };
        newSchedule[day].push(item);
        setSchedule(newSchedule);
    };

    const _onChange = (day, position, name, value) => {
        let newSchedule = { ...schedule };
        if (name === "tuition") {
            newSchedule[day][position][name] = numberWithoutCommas(value);
        } else {
            newSchedule[day][position][name] = value;
        }
        setSchedule(newSchedule);
    };

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm className="new-form" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} lg={12}>
                        <FieldFloatItem
                            label="Chi nhánh"
                            name="branch_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn chi nhánh",
                                },
                            ]}
                        >
                            <Select
                                disabled={user.branch_id}
                                // onChange={handleChangeType}
                            >
                                {listBranch &&
                                    listBranch.map((item, i) => {
                                        return (
                                            <Option key={i} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </FieldFloatItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <FieldFloatItem
                            rules={[{ required: true, message: "Nhập tên sân tập!" }]}
                            label="Tên sân tập"
                            name="name"
                        >
                            <Input />
                        </FieldFloatItem>
                    </Col>
                    <Col xs={24}>
                        <Row>
                            <Col xs={24} lg={3}>
                                Lịch học:
                            </Col>
                            <Col xs={24}>
                                <Row
                                    gutter={{
                                        xs: 8,
                                        sm: 8,
                                        md: 16,
                                    }}
                                >
                                    <Col xs={24} sm={24} md={2}></Col>
                                    <Col xs={24} sm={24} md={16}>
                                        <Row
                                            gutter={{
                                                xs: 8,
                                                sm: 8,
                                                md: 16,
                                            }}
                                            style={{ marginBottom: 5 }}
                                        >
                                            <Col span={7}>
                                                <div>Bắt đầu</div>
                                            </Col>
                                            <Col span={7}>
                                                <div>Kết thúc</div>
                                            </Col>
                                            <Col span={7}>
                                                <div>Tiền thuê</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} lg={24}>
                                {[
                                    ["1", "T2"],
                                    ["2", "T3"],
                                    ["3", "T4"],
                                    ["4", "T5"],
                                    ["5", "T6"],
                                    ["6", "T7"],
                                    ["7", "CN"],
                                ].map((item, index) => {
                                    return (
                                        <Row
                                            style={{
                                                marginBottom: "10px",
                                                borderBottom: "1px dashed rgb(208, 208, 208)",
                                                padding: "10px 0",
                                            }}
                                            gutter={{
                                                xs: 8,
                                                sm: 8,
                                                md: 16,
                                            }}
                                            key={index}
                                        >
                                            <Col xs={24} sm={24} md={2}>
                                                <label className="item-label">{item[1]}</label>
                                            </Col>
                                            <Col xs={24} sm={24} md={16}>
                                                {_renderSchedule(schedule[`day_${item[0]}`], `day_${item[0]}`)}
                                                <Button
                                                    onClick={() => _addSchedule(`day_${item[0]}`)}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Thêm khung giờ
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginTop: 15 }} xs={24}>
                        <FieldFloatItem label="Ghi chú" name="note">
                            <Input.TextArea rows={3} />
                        </FieldFloatItem>
                    </Col>
                    {acl.hasAllowed(getRole(), "course", "update") && (
                        <Col xs={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {id ? "Cập nhật" : "Tạo mới"}
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </NewForm>
        </CenterArea>
    );
};

export default Detail;
