import { UPDATE_MODAL } from "@actions/modal";

const initialState = {
    dataInput: {},
};

export default function modal(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_MODAL:
            return action.payload.data;
        default:
            return state;
    }
}
