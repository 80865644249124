import React, { useState, useEffect } from "react";
import { Button, Form, Input, Checkbox, Row, Col, notification } from "antd";

import CenterArea from "@components/CenterArea";
import { useParams, useHistory } from "react-router-dom";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import "./index.scss";
import { validateObject } from "@utils";
import { api_getListTamplate, api_getOneRole, api_updateRole, api_createRole } from "./apiRole";

let FormItem = Form.Item;
const Detail = () => {
    const [form] = NewForm.useForm();
    let { role_id } = useParams();
    let history = useHistory();

    const [aclData, setAclData] = useState({
        name: "",
        description: "",
        data: [],
    });
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        let roleData = {};

        aclData.data.map((resource) => {
            let actionData = {};

            resource.actions.map((action) => {
                actionData[action.key] = action.value;
            });

            roleData[resource.key] = actionData;
        });
        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateObject(values);
                dataSubmit["content"] = roleData;
                console.log("values: ", dataSubmit);
                if (role_id) {
                    let res = await api_updateRole(role_id, dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                } else {
                    let res = await api_createRole(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        history.goBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                notification.error({
                    message: "Cảnh báo",
                    description: "Lỗi hệ thống",
                });
                setLoading(false);
            });
    };

    const initFetch = async () => {
        setLoading(true);
        if (role_id) {
            const res = await api_getOneRole(role_id);
            if (res.status) {
                form.setFieldsValue({
                    name: res.data.name,
                    description: res.data.description,
                });
                setAclData(res.data);
                setLoading(false);
            } else {
                setTimeout(() => setLoading(false), 1000);
            }
            setVisible(true);
        } else {
            const res = await api_getListTamplate();
            if (res.status) {
                setAclData({ ...aclData, data: res.data });
                setLoading(false);
            }
            setVisible(true);
        }
    };

    useEffect(() => {
        initFetch();
        return () => {
            if (!history.location.pathname.includes("/role")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const onChange = (resource, checkedValues) => {
        let dataRole = aclData.data;
        resource.actions.map((item, key) => {
            if (checkedValues.indexOf(item.key) != -1) {
                resource.actions[key].value = true;
            } else {
                resource.actions[key].value = false;
            }
        });
        dataRole.map((item, key) => {
            if (item.key == resource.key) {
                dataRole[key] = resource;
            }
        });
        setAclData({ ...aclData, data: dataRole });
    };

    const validateCheckAll = (resource) => {
        let tmp = resource.actions.filter((item) => !item.value);
        return !(tmp.length > 0);
    };

    const handleCheckall = (e, resource) => {
        let dataRole = aclData.data;
        if (e.target.checked) {
            resource.actions.map((item, key) => {
                resource.actions[key].value = true;
            });
        } else {
            resource.actions.map((item, key) => {
                resource.actions[key].value = false;
            });
        }
        dataRole.map((item, key) => {
            if (item.key == resource.key) {
                dataRole[key] = resource;
            }
        });
        setAclData({ ...aclData, data: dataRole });
    };

    const getCheckedList = (resource) => {
        return resource.actions.map((item) => {
            if (item.value) {
                return item.key;
            }
        });
    };

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm form={form} onFinish={onSubmit} className="new-form" visible={visible}>
                <FieldFloatItem label="Tên phân quyền" name="name">
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem label="Mô tả" name="description">
                    <Input />
                </FieldFloatItem>
                <FormItem label="Quyền">
                    {console.log("object", aclData)}
                    {aclData &&
                        aclData.data.map((resource, key) => {
                            return (
                                <div key={key}>
                                    <strong
                                        style={{
                                            width: "100%",
                                            display: "block",
                                            height: 30,
                                            marginTop: 5,
                                        }}
                                    >
                                        {resource.name}
                                    </strong>
                                    <Checkbox
                                        checked={validateCheckAll(resource)}
                                        onChange={(e) => handleCheckall(e, resource)}
                                    >
                                        All
                                    </Checkbox>
                                    <Checkbox.Group
                                        onChange={(values) => onChange(resource, values)}
                                        value={getCheckedList(resource)}
                                    >
                                        <Row type="flex">
                                            {resource.actions.map((action, key) => {
                                                return (
                                                    <Col key={key}>
                                                        <Checkbox value={action.key}>{action.name}</Checkbox>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                            );
                        })}
                </FormItem>
                <Form.Item>
                    <Row type="flex" style={{ margin: "20px 10px" }}>
                        <Col style={{ marginRight: 10 }}>
                            <Button type="default" onClick={() => history.goBack()}>
                                Quay lại
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" loading={loading} onClick={onSubmit}>
                                {role_id ? "Cập nhật" : "Lưu"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </NewForm>
        </CenterArea>
    );
};

export default Detail;
