import api from "@api";
export const api_getListRole = (param) => {
    const url = `role`;
    return api.get(url, { params: param });
};
export const api_getListTamplate = (param) => {
    const url = `/role/template`;
    return api.get(url, { params: param });
};
export const api_createRole = (param) => {
    const url = `role`;
    return api.post(url, param);
};
export const api_getOneRole = (role_id) => {
    const url = `role/${role_id}`;
    return api.get(url);
};
export const api_updateRole = (role_id, param) => {
    const url = `role/${role_id}`;
    return api.put(url, param);
};

export const api_getDeleteRole = (role_id) => {
    const url = `role/${role_id}`;
    return api.delete(url);
};
