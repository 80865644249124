import React, { useState, useEffect } from "react";
import { Row, Col, Table, notification, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsTabStudent";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";

import { useHistory } from "react-router-dom";

import { getFilterString } from "@utils";
import { api_getStudentsOfCourse } from "./apiCourse";
import "./index.scss";
import ExportStudent from "./ExportStudent";

let Search = Input.Search;

function StudentComponent(props) {
    // const acl = useSelector((state) => state.acl.acl);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });
    const { id } = props;

    const [data, setData] = useState([]);
    const [dataExport, setDataExport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let sortQuery = "";
        let newFilterData = { ...filterData };
        let pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            sortQuery = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
            fetch(pagination.current, sortQuery);
        } else {
            if (Object.keys(filters).length !== 0) {
                if (filters.statusCourseOfStudent && filters.statusCourseOfStudent.length > 0) {
                    newFilterData.status = filters.statusCourseOfStudent;
                } else {
                    delete newFilterData.status;
                }
            }
            setFilterData(newFilterData);
            fetch(pagination.current, sortQuery, newFilterData);
        }
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        const res = await api_getStudentsOfCourse({
            _relations: "student",
            _filter: `course_id:${id};` + filter,
            _page: page,
            _perPage: 10,
            _orderBy: sort,
        });
        let convertData = [];
        if (res.status) {
            if (res.data) {
                setLoading(false);
                setDataExport(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    let data = res.data[i].student.data;
                    data.courseStudentId = res.data[i].id;
                    data.statusCourseOfStudent = res.data[i].status;
                    convertData.push(data);
                }
                setData(convertData);
                let newpagination = { ...pagination };
                newpagination.total = res.meta.pagination.total;
                newpagination.pageSize = res.meta.pagination.per_page;
                newpagination.current = res.meta.pagination.current_page;
                setPagination(newpagination);
            }
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
        });
        setColumns(columnConfig);
    }, [pagination]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        let newFilterData = { ...filterData };
        newFilterData.status = ["1", "4"];
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
        // setColumns(newColumns);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        window.open("/student-detail/" + record.id, "_blank");
                        // history.push("/student-detail/" + record.id);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
            ],
        });
    }, []);

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 10 }}>
                    <Search
                        placeholder="Tìm kiếm học viên"
                        style={{
                            width: 250,
                            marginBottom: 0,
                        }}
                        onSearch={(value) => handleSearch(value)}
                    />
                    <ExportStudent data={dataExport} idCourse={id} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <Table
                    onRow={onRowTable}
                    bordered
                    columns={columns}
                    rowKey={(record) => record.courseStudentId}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                        x: 1100,
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default StudentComponent;
