import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, Modal, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import api from "@api";
import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getFilterString, getHeightOfMainContent } from "@utils";
import { api_getAllCourse } from "./apiCourse";
import { api_getAllTeacher, api_getAllBranch } from "@api/globalApi";

let { Search } = Input;

const Course = (props) => {
    // const acl = useSelector((state) => state.acl.acl);

    const { history } = props;

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [listTeacher, setListTeacher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listBranch, setBranch] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xoá khóa học này?",
            onOk() {
                api.post(`/course/inactive/${record.id}`).then(function (res) {
                    if (res.status == true) {
                        fetch();
                        notification.success({
                            message: "Thông báo",
                            description: "Xoá thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Xoá thất bại, vui lòng thử lại sau",
                        });
                    }
                });
            },
        });
    };

    const _getAllTeacher = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setListTeacher(res.data);
        }
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (_pagination, filters, sorter) => {
        let newSorter = "id:desc";
        let newFilterData = { ...filterData };
        let pager = { ...pagination };
        pager.current = _pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            // fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
            newSorter = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
        } else {
            if (Object.keys(filters).length !== 0) {
                if (filters.type && filters.type.length > 0) {
                    newFilterData.type = filters.type;
                } else {
                    delete newFilterData.type;
                }
                if (!branchRedux && filters.branch_id && filters.branch_id.length > 0) {
                    newFilterData.branch_id = filters.branch_id || "";
                } else {
                    newFilterData.branch_id = "";
                }
            }
            setFilterData(newFilterData);
            fetch(_pagination.current, newSorter, newFilterData);
        }
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        filterData.active = 1;
        let filters = getFilterString(filterData);
        const res = await api_getAllCourse({ page, filters });
        setLoading(false);
        let newPagination = {
            ...pagination,
            ...res.meta.pagination,
            current: res.meta.pagination.current_page,
            pageSize: res.meta.pagination.per_page,
            total: res.meta.pagination.total,
        };
        setData(res.data);
        setPagination(newPagination);
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listTeacher,
            listBranch,
            branchRedux,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push(`/course-detail/${record.id}`);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Chỉnh sửa
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xoá
                        </>
                    ),
                },
            ],
        });
    }, [listBranch, pagination, branchRedux, listTeacher]);

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    useEffect(() => {
        const _getAllBranch = async () => {
            const res = await api_getAllBranch();
            if (res.status) {
                if (res.data) {
                    setBranch(res.data);
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        _getAllTeacher();
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/course-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search placeholder="Tìm kiếm tên khóa học" style={{ width: 250 }} onSearch={_handleSearchTable} />
                    <Button onClick={() => history.push("/course-detail")} type="primary">
                        Thêm mới
                    </Button>
                </div>
                <Row style={{ marginBottom: 0 }}>
                    <Table
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                            x: 1400,
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
};

export default Course;
