import FormEventListener from "./FormEventListener";

class FormInstance {
    constructor(form) {
        this.form = form;
        this.eventListener = new FormEventListener(form);
    }
    setRef(ref) {
        this.ref = ref;
        this.eventListener.setRef(ref);
    }
    getFieldError(name) {
        return this.form.getFieldError(name);
    }
    getFieldsError(nameList) {
        return this.form.getFieldsError(nameList);
    }
    getFieldInstance(name) {
        return this.form.getFieldInstance(name);
    }
    getFieldValue(name) {
        return this.form.getFieldValue(name);
    }
    getFieldsValue(nameList, filterFunc) {
        return this.form.getFieldsValue(nameList, filterFunc);
    }
    getInternalHooks(key) {
        return this.form.getInternalHooks(key);
    }
    isFieldTouched(name) {
        return this.form.isFieldTouched(name);
    }
    isFieldValidating(name) {
        return this.form.isFieldValidating(name);
    }
    isFieldsTouched() {
        return this.form.isFieldsTouched();
    }
    isFieldsValidating(nameList) {
        return this.form.isFieldsValidating(nameList);
    }
    scrollToField(name) {
        return this.form.scrollToField(name);
    }
    resetFields() {
        this.form.resetFields();
        setTimeout(() => {
            this.initEvents();
        });
    }
    setFields(fields) {
        return this.form.setFields(fields);
    }
    setFieldsValue(fields) {
        this.form.setFieldsValue(fields);
        this.eventListener.triggerNewValues(Object.keys(fields));
    }
    submit() {
        return this.form.submit();
    }
    validateFields(nameList, options) {
        return this.form.validateFields(nameList, options);
    }
    initEvents() {
        this.eventListener.initEvents();
    }
    initEvent(name) {
        this.eventListener.initEvent(name);
    }
    triggerNewValues(keys) {
        this.eventListener.triggerNewValues(keys);
    }
}

export default FormInstance;
