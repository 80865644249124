import menus from "@api/mock/menu";

export const GET_ALL_MENU = "GET_ALL_MENU";
export const UPDATE_NAVPATH = "UPDATE_NAVPATH";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const RELOAD_MENU = "RELOAD_MENU";

export function updateNavPath(path, key) {
    return {
        type: UPDATE_NAVPATH,
        payload: {
            data: path,
            key: key,
        },
    };
}

export function getAllMenu() {
    return {
        type: GET_ALL_MENU,
        payload: {
            data: menus,
        },
    };
}

export function toggleMenu(isOpened) {
    return {
        type: TOGGLE_MENU,
        payload: {
            data: !isOpened,
        },
    };
}
