import React, { useState, useEffect } from "react";
import { Button, Form, Input, Row, Col, notification, Tabs, Select, DatePicker } from "antd";

import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { validateObject, numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { getUserInfo } from "@utils/auth";

import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "../../components/CenterArea";
import { InputCurrency } from "@components/Input";
import {
    createStudentCourse,
    getCourseOfType,
    getEndCourse,
    getStudentCourse,
    updateStudentCourse,
} from "./apiStudent";
import { api_getAllBranch, api_getAllBranchOptions } from "@api/globalApi";

let { TextArea } = Input;
let { TabPane } = Tabs;
let { Option } = Select;

let listStatus = [
    { id: "1", name: "Đang học" },
    // { id: "2", name: "Kết thúc" },
    { id: "3", name: "Hủy bỏ" },
    { id: "4", name: "Bảo lưu" },
];

// let listCombo = [
//     { id: 1, name: "1 khóa" },
//     { id: 2, name: "2 khóa" },
//     { id: 3, name: "3 khóa" },
//     { id: 4, name: "4 khóa" },
//     { id: 5, name: "5 khóa" },
// ];

const CourseDetail = () => {
    let history = useHistory();
    let { state, pathname } = useLocation();
    let { student_id, course_id, student_course_id } = useParams();
    const [form] = NewForm.useForm();
    let user = getUserInfo();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [listCourse, setListCourse] = useState([]);
    const [listBranch, setListBranch] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [statusId, setStatusId] = useState(null);
    const [visibleDateReserve, setVisibleDateReserve] = useState(false);

    const handleBack = () => {
        history.goBack();
    };

    const onSubmit = () => {
        setLoading(true);

        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateObject(values);
                dataSubmit.student_id = student_id;
                dataSubmit.tuition = numberWithoutCommas(dataSubmit.tuition);
                dataSubmit.amount_expected = numberWithoutCommas(dataSubmit.amount_expected);
                dataSubmit.begin_date = dataSubmit.begin_date ? dataSubmit.begin_date.format("YYYY-MM-DD") : null;
                dataSubmit.end_date = dataSubmit.end_date ? dataSubmit.end_date.format("YYYY-MM-DD") : null;
                delete dataSubmit.type;
                console.log("values: ", dataSubmit);
                if (course_id) {
                    if (statusId == 4) {
                        dataSubmit.date_continue = dataSubmit.date_continue
                            ? dataSubmit.date_continue.format("YYYY-MM-DD")
                            : moment(new Date(), "YYYY-MM-DD");
                    }
                    dataSubmit.student_course_id = student_course_id;
                    let res = await updateStudentCourse(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                        setVisibleDateReserve(false);
                        initFetch();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                } else {
                    let res = await createStudentCourse(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        history.goBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                notification.error({
                    message: "Cảnh báo",
                    description: "Lỗi hệ thống",
                });
                setLoading(false);
            });
    };

    const initFetch = async () => {
        const res = await getStudentCourse(student_course_id, {
            student_id,
            course_id,
        });
        if (res.status) {
            let resCourse = await getCourseOfType({
                _paging: 0,
                _filter: `branch_id:${res.data?.branch_id};type:${res.data.type}`,
            });
            if (resCourse.status) {
                setListCourse(resCourse.data);
            }
            setBranchId(res.data?.branch_id?.toString());
            setStatusId(res.data?.status?.toString());
            form.setFieldsValue({
                branch_id: res.data?.branch_id?.toString(),
                type: res.data.type,
                course_id: res.data?.course_id?.toString(),
                // combo: res.data?.combo?.toString(),
                status: res.data?.status?.toString(),
                note: res.data.note,
                tuition: res.data.tuition ? numberWithCommas(res.data.tuition) : null,
                begin_date: res.data.begin_date ? moment(res.data.begin_date, "YYYY-MM-DD") : null,
                end_date: res.data.end_date ? moment(res.data.end_date, "YYYY-MM-DD") : null,
                date_continue: moment(new Date(), "YYYY-MM-DD").add(1, "days"),
            });
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        const _getAllBranch = async () => {
            const res = await api_getAllBranchOptions();
            if (res.status) {
                if (res.data) {
                    setListBranch(toArray(res.data));
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranch();
        setBranchId(user.branch_id);
        if (course_id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            setVisible(true);
            form.setFieldsValue({
                branch_id: user?.branch_id?.toString(),
                begin_date: moment(new Date(), "YYYY-MM-DD"),
                // combo: 1,
            });
        }
    }, []);

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    const handleChangeType = async (value) => {
        let res = await getCourseOfType({
            _paging: 0,
            _filter: `branch_id:${branchId};type:${value};active:1;status:1`,
        });
        if (res.status) {
            setListCourse(res.data);
        }
        form.setFieldsValue({ course_id: "" });
    };

    const handleChangeBranch = async (value) => {
        setBranchId(value);
        form.setFieldsValue({ course_id: "", tuition: null, type: "" });
    };

    const handleChangeCourse = async (value) => {
        setBranchId(value);
        let _tuition =
            listCourse &&
            listCourse.filter((item) => {
                return item.id == value;
            });
        let date = form.getFieldValue("begin_date");
        let begin_date = date ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
        _funcGetEndDate(begin_date, value);
        form.setFieldsValue({
            tuition: _tuition[0].tuition ? numberWithCommas(_tuition[0].tuition) : null,
        });
    };

    const _funcGetEndDate = async (
        beginDate = form.getFieldValue("begin_date"),
        course_id = form.getFieldValue("course_id")
    ) => {
        if (beginDate && course_id) {
            let courseData = listCourse.filter((item) => {
                return item.id == course_id;
            });
            let dataSubmit = {};
            let data = {};
            data.begin_date = beginDate;
            if (courseData.length > 0) {
                data.duration = courseData[0].duration;
                let arr = [1, 2, 3, 4, 5, 6, 7];
                for (let i = 1; i < arr.length + 1; i++) {
                    data["day_" + i] = courseData[0]["day_" + i];
                }
            }
            dataSubmit.data = data;
            dataSubmit.course_id = course_id;
            // dataSubmit.combo = combo;

            let res = await getEndCourse(dataSubmit);
            if (res.status) {
                form.setFieldsValue({
                    end_date: res.end_date ? moment(res.end_date, "YYYY-MM-DD") : null,
                    count_lesson_expected: res.count_lesson_expected,
                    amount_expected: res.amount_expected ? numberWithCommas(res.amount_expected) : null,
                });
            }
        }
    };

    // const handleChangeCombo = (value) => {
    //     let date = form.getFieldValue("begin_date");
    //     let begin_date = date ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
    //     _funcGetEndDate(begin_date, value);
    // };

    const onChangeBeginDate = async (date, dateString) => {
        let beginDate = dateString != "" ? moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
        _funcGetEndDate(beginDate);
    };

    const onChangeStatus = (value) => {
        if (statusId == 4 && value == 1) {
            setVisibleDateReserve(true);
        } else {
            setVisibleDateReserve(false);
        }
    };

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin khóa học" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem
                                    label="Chi nhánh"
                                    name="branch_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng chọn chi nhánh",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={!user.is_admin || !pathname.includes("/add-student-course")}
                                        onChange={handleChangeBranch}
                                    >
                                        {listBranch &&
                                            listBranch.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id.toString()}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem
                                    label="Loại lớp"
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng chọn loại lớp",
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={handleChangeType}
                                        disabled={!branchId || !pathname.includes("/add-student-course")}
                                    >
                                        <Option value="lopcongdong">Lớp cộng đồng</Option>
                                        <Option value="lopnangkhieu">Lớp năng khiếu</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Khóa học" name="course_id">
                                    <Select
                                        disabled={
                                            !form.getFieldValue("type") || !pathname.includes("/add-student-course")
                                        }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={handleChangeCourse}
                                    >
                                        {listCourse.map((item, i) => {
                                            return (
                                                <Option key={i} value={item.id.toString()}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label={pathname.includes("/add-student-course") ? "Học phí khóa học" : "Học phí"}
                                    name="tuition"
                                >
                                    <InputCurrency disabled={true} />
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        {/* <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Hình thức thanh toán" name="checkout_method">
                                    <Select>
                                        <Option value="tienmat">Tiền mặt</Option>
                                        <Option value="chuyenkhoan">Chuyển khoản</Option>
                                        <Option value="quetthe">Quẹt thẻ</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row> */}
                        {/* <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Thời gian muốn học" name="combo">
                                    <Select
                                        disabled={!user.is_admin || !pathname.includes("/add-student-course")}
                                        onChange={handleChangeCombo}
                                    >
                                        {listCombo &&
                                            listCombo.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row> */}
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Ngày bắt đầu"
                                    name="begin_date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng chọn ngày bắt đầu",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        disabled={!pathname.includes("/add-student-course")}
                                        style={{ width: "100%" }}
                                        placeholder={null}
                                        format="DD/MM/YYYY"
                                        onChange={onChangeBeginDate}
                                    />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Ngày kết thúc" name="end_date">
                                    <DatePicker
                                        disabled={true}
                                        style={{ width: "100%" }}
                                        placeholder={null}
                                        format="DD/MM/YYYY"
                                    />
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        {pathname.includes("/add-student-course") && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Số buổi học trong tháng" name="count_lesson_expected">
                                        <Input disabled={true} />
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Tiền phải đóng" name="amount_expected">
                                        <InputCurrency disabled={true} style={{ color: "rgba(0, 0, 0, 0.9)" }} />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )}
                        {!pathname.includes("/add-student-course") && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={24}>
                                    <FieldFloatItem label="Trạng thái" name="status">
                                        <Select
                                            onChange={onChangeStatus}
                                            disabled={form.getFieldValue("status") == "3"}
                                        >
                                            {listStatus &&
                                                listStatus.map((item, i) => {
                                                    return (
                                                        <Option key={i} value={item.id.toString()}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )}
                        {!pathname.includes("/add-student-course") && visibleDateReserve && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={24}>
                                    <FieldFloatItem label="Ngày học lại" name="date_continue">
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            style={{ width: "100%" }}
                                            placeholder={null}
                                            format="DD/MM/YYYY"
                                        />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Ghi chú" name="note">
                                    <TextArea name="note" rows={2} />
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row type="flex">
                                        <Col style={{ marginRight: 10 }}>
                                            <Button type="default" onClick={handleBack}>
                                                Quay lại
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button type="primary" loading={loading} htmlType="submit">
                                                Lưu
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default CourseDetail;
