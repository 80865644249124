import React from "react";
import { Image } from "antd";
import moment from "moment";

export const createColumnConfig = (props) => {
    const { pagination } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 80,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>
                    {pagination != undefined ? (pagination.current - 1) * pagination.pageSize + index + 1 : index + 1}
                </React.Fragment>
            ),
        },
        {
            title: "Ảnh học viên",
            dataIndex: "image",
            width: 130,
            align: "center",
            render: (text, record, index) => {
                return text ? <Image src={text} style={{ width: 75, height: "auto" }} /> : <div>Chưa có</div>;
            },
        },
        {
            title: "Họ tên học viên",
            dataIndex: "name",
            width: 170,
            align: "center",
        },
        {
            title: "Sinh nhật",
            dataIndex: "birthday",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.birthday ? moment(record.birthday, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
        {
            title: "Họ tên phụ huynh",
            dataIndex: "parent_name",
            width: 170,
            align: "center",
        },
        {
            title: "Số ĐT",
            dataIndex: "phone",
            width: 150,
            align: "center",
        },
        {
            title: "Giới tính",
            dataIndex: "gender",
            width: 100,
            align: "center",
            render: (text, record) => <React.Fragment>{(text == 0 && "Nữ") || (text == 1 && "Nam")}</React.Fragment>,
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            width: 200,
            align: "center",
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: 200,
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    return defaultColumn;
};
