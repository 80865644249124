export default {
    lead: "tk_lead",
    student: "tk_student",
    teacher: "tk_teacher",
    feedback: "tk_feedback",
    lesson: "tk_lesson",
    classroom: "tk_classroom",
    invoice: "tk_invoice",
    course: "tk_course",
    lead_study_abroad: "tk_lead_study_abroad",
};
