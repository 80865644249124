import { Spin } from "antd";
import React, { useEffect, useState } from "react";

import "./lazyload.scss";

function LazyLoad(props) {
    const [active, setActive] = useState(false);
    const [show, setShow] = useState(false);
    const { delay = 650 } = props;

    useEffect(() => {
        setTimeout(() => {
            setActive(true);
        }, 350);
        setTimeout(() => {
            setShow(true);
        }, delay + 350);
    }, []);

    return (
        <>
            {!show ? (
                <div
                    style={{
                        textAlign: "center",
                        paddingTop: 100,
                        paddingBottom: 100,
                    }}
                >
                    <Spin />
                </div>
            ) : null}
            <div className="lazyload-contents" style={{ opacity: show ? 1 : 0 }}>
                {active ? props.children : null}
            </div>
        </>
    );
}

export default React.memo(LazyLoad);
