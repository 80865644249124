import React from "react";
import { Input, TimePicker as AntdTimePicker } from "antd";
import { numberWithCommas } from "@utils";

function InputNumber(props) {
    let { onChange, className = "" } = props;
    let classList = className.split(" ");
    classList.push("custom-input-number");
    let customClassName = classList.filter((c) => c).join(" ");

    return (
        <Input
            {...props}
            onChange={(e) => {
                e.target.value = numberWithCommas(e.target.value);
                onChange(e);
            }}
            maxLength={13}
            className={customClassName}
        />
    );
}

function InputCurrency(props) {
    return <InputNumber {...props} addonAfter="VNĐ" />;
}

function TimePicker(props) {
    const { onChange } = props;

    return (
        <AntdTimePicker
            {...props}
            showNow={false}
            onSelect={(value) => {
                onChange(value);
            }}
            popupClassName="timepicker-without-ok-button"
        />
    );
}

export { InputNumber, InputCurrency, TimePicker };
