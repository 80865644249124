import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Row, Col, notification } from "antd";

import { useHistory, useParams } from "react-router-dom";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "@components/CenterArea";

import { InputCurrency } from "@components/Input";
import { validateObject, numberWithCommas, numberWithoutCommas } from "@utils";
import { api_getOneBranch, api_createBranch, api_updateBranch } from "./apiBranch";
import "./index.scss";

const Detail = () => {
    const [form] = NewForm.useForm();
    let history = useHistory();
    let { branch_id } = useParams();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateObject(values);
                console.log("values: ", dataSubmit);
                dataSubmit.yard_rent = numberWithoutCommas(dataSubmit.yard_rent);
                delete dataSubmit.yard_rent;
                delete dataSubmit.number_yard;
                if (branch_id) {
                    let res = await api_updateBranch(branch_id, dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                } else {
                    let res = await api_createBranch(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        history.goBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                notification.error({
                    message: "Cảnh báo",
                    description: "Lỗi hệ thống",
                });
                setLoading(false);
            });
    };

    const initFetch = async () => {
        const res = await api_getOneBranch(branch_id);
        if (res.status) {
            form.setFieldsValue({
                name: res.data.name,
                branch_id: res.data?.branch_id?.toString(),
                address: res.data.address,
                // active: res.data.active,
                number_yard: res.data.number_yard != 0 ? res.data.number_yard : "Chưa có",
                yard_rent: res.data.yard_rent ? numberWithCommas(res.data.yard_rent) : "Chưa có",
            });
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        if (branch_id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            setVisible(true);
        }
        return () => {
            if (!history.location.pathname.includes("/branch")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                <FieldFloatItem label="Tên chi nhánh" name="name">
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem label="Địa chỉ" name="address">
                    <Input />
                </FieldFloatItem>
                {branch_id && (
                    <Row gutter={[16]}>
                        <Col xs={24} lg={12}>
                            <FieldFloatItem label="Tổng số sân" name="number_yard">
                                <Input disabled />
                            </FieldFloatItem>
                        </Col>
                        <Col xs={24} lg={12}>
                            <FieldFloatItem label="Tiền thuê sân" name="yard_rent">
                                <InputCurrency disabled />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                )}
                {/* <Form.Item name="active">
                    <Radio.Group>
                        <Radio value={1}>Mở</Radio>
                        <Radio value={0}>Đóng</Radio>
                    </Radio.Group>
                </Form.Item> */}

                <Form.Item>
                    <Row type="flex" style={{ marginTop: 20 }}>
                        <Col style={{ marginRight: 10 }}>
                            <Button type="default" onClick={() => history.push("/branch")}>
                                Quay lại
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" loading={loading} onClick={onSubmit}>
                                {branch_id ? "Cập nhật" : "Lưu"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </NewForm>
        </CenterArea>
    );
};

export default Detail;
