import React from "react";
import moment from "moment";

export const createColumnConfig = (props) => {
    const { pagination } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: "10%",
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Tên phân quyền",
            dataIndex: "name",
            width: "25%",
            align: "left",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            width: "30%",
            align: "left",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            align: "left",
            render: (text, record) =>
                record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "",
            width: "22%",
        },
    ];

    return defaultColumn;
};
