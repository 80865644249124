import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Radio, Row, Col, notification, Tabs, Select, DatePicker, Upload, Rate } from "antd";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { validateObject } from "@utils";
import CenterArea from "@components/CenterArea";
import { createStudent, updateStudent, getStudent } from "./apiStudent";
import { api_uploadImage, api_deleteImage } from "@api/globalApi";
import POSITIONS from "@constants/positions";
import "./index.scss";

let RadioGroup = Radio.Group;
let { TextArea } = Input;
let { TabPane } = Tabs;
const { Option, OptGroup } = Select;
let FormItem = Form.Item;

const Detail = () => {
    let history = useHistory();
    let { student_id } = useParams();
    const [form] = NewForm.useForm();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [image, setImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);

    const [rating, setRating] = useState([
        { id: "qua_nguoi", name: "Qua người", value: "0" },
        { id: "kem_nguoi", name: "Kèm người", value: "0" },
        { id: "dan_bong", name: "Dẫn bóng", value: "0" },
        { id: "chuyen", name: "Chuyền", value: "0" },
        { id: "kiem_soat_bong", name: "Kiểm soát bóng", value: "0" },
        { id: "kien_tao", name: "Kiến tạo", value: "0" },
        { id: "sut", name: "Sút", value: "0" },
    ]);
    const handleBack = () => {
        history.push("/student");
    };

    const _handleChangeImage = ({ fileList }) => {
        if (fileList.length == 0) {
            if (typeof image === "string") {
                setOldImage(image);
            }
            setImage(null);
        } else {
            setImage(fileList[0]);
        }
    };

    const onSubmit = async () => {
        setLoading(true);
        const sendData = (imageurl) => {
            form.validateFields()
                .then(async (values) => {
                    let dataSubmit = validateObject(values);
                    dataSubmit.gender = +dataSubmit.gender;
                    dataSubmit.birthday = dataSubmit.birthday ? dataSubmit.birthday.format("YYYY-MM-DD") : null;
                    dataSubmit.image = imageurl ? imageurl : null;
                    const newObj = {};
                    for (let i of rating) {
                        newObj[i.id] = i.value;
                    }
                    dataSubmit.skill = newObj;
                    if (student_id) {
                        let res = await updateStudent(student_id, dataSubmit);
                        if (res.status) {
                            notification.success({
                                message: "Thông báo",
                                description: "Cập nhật thành công",
                            });
                        } else {
                            notification.error({
                                message: "Cảnh báo",
                                description: res.message || "Lỗi hệ thống",
                            });
                        }
                        setLoading(false);
                    } else {
                        let res = await createStudent(dataSubmit);
                        if (res.status) {
                            notification.success({
                                message: "Thông báo",
                                description: "Tạo mới thành công",
                            });
                            history.goBack();
                        } else {
                            notification.error({
                                message: "Cảnh báo",
                                description: res.message || "Lỗi hệ thống",
                            });
                        }
                        setLoading(false);
                    }
                })
                .catch(() => {
                    notification.error({
                        message: "Cảnh báo",
                        description: "Lỗi hệ thống",
                    });
                    setLoading(false);
                });
        };
        if (image) {
            if (typeof image !== "string") {
                const resImage = await api_uploadImage(image);
                if (resImage.status) {
                    sendData(resImage.result.file);
                }
            } else sendData(image);
        } else {
            if (oldImage) {
                api_deleteImage(oldImage);
            }
            sendData();
        }
    };

    const uploadButton = (
        <div>
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const initFetch = async () => {
        const res = await getStudent(student_id);
        if (res.status) {
            const skill = res.data?.skill;
            const newRating = [...rating];
            for (let i of newRating) {
                i.value = skill?.[i.id];
            }
            setRating(newRating);
            setImage(res.data.image);
            form.setFieldsValue({
                name: res.data.name,
                parent_name: res.data.parent_name,
                height: res.data.height,
                weight: res.data.weight,
                phone: res.data.phone,
                email: res.data.email,
                gender: res.data.gender + "",
                birthday: res.data.birthday ? moment(res.data.birthday, "YYYY-MM-DD") : null,
                address: res.data.address,
                country: res.data.country,
                side_foot: res.data.side_foot,
                position: res.data.position || [],
                skill: res.data.skill,
                achievement: res.data.achievement,
                note: res.data.note,
                born_year: res.data.born_year,
            });
            setLoading(false);
        } else {
            const timeoutFunc = setTimeout(() => setLoading(false), 1000);
            return () => {
                clearTimeout(timeoutFunc);
            };
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        if (student_id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            form.setFieldsValue({
                gender: "1",
                birthday: null,
                country: "Việt Nam",
                side_foot: "2",
                position: [],
            });
            setVisible(true);
        }
    }, []);

    const handleChangeRating = (value, id) => {
        let newRating = [...rating];
        for (let i of newRating) {
            if (i.id === id) {
                i.value = value + "" || "0";
            }
        }
        setRating(newRating);
        form.setFieldsValue({
            skill: newRating,
        });
    };

    return (
        <Tabs
            defaultActiveKey="1"
            onTabClick={(data) => {
                if (data == 2) {
                    history.push("/student-course/" + student_id);
                }
            }}
        >
            <TabPane tab="Thông tin học viên" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form student-form" form={form} onFinish={onSubmit} visible={visible}>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} md={6}>
                                <Upload
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("Ok");
                                        }, 0);
                                    }}
                                    listType="picture-card"
                                    fileList={
                                        image
                                            ? image.uid
                                                ? [image]
                                                : [
                                                      {
                                                          uid: "1",
                                                          name: "image.png",
                                                          status: "done",
                                                          url: image,
                                                      },
                                                  ]
                                            : []
                                    }
                                    // onPreview={this.handlePreview}
                                    onChange={_handleChangeImage}
                                >
                                    {!image && uploadButton}
                                </Upload>
                            </Col>
                            <Col xs={24} md={18}>
                                <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem
                                            label="Họ tên học viên"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                            <Col xs={12}>
                                                <FieldFloatItem label="Chiều cao (cm)" name="height">
                                                    <Input />
                                                </FieldFloatItem>
                                            </Col>
                                            <Col xs={12}>
                                                <FieldFloatItem label="Cân nặng (kg)" name="weight">
                                                    <Input />
                                                </FieldFloatItem>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem label="Ngày sinh" name="birthday">
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                placeholder={null}
                                                format="DD/MM/YYYY"
                                            />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <FormItem label="Giới tính" name="gender">
                                            <RadioGroup name="gender">
                                                <Radio value="0">Nữ</Radio>
                                                <Radio value="1">Nam</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem
                                            label="Họ tên phụ huynh"
                                            name="parent_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập số điện thoại",
                                                },
                                            ]}
                                            label="Số điện thoại"
                                            name="phone"
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Email" name="email">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Địa chỉ" name="address">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Quốc gia" name="country">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col span={24}>
                                        <FieldFloatItem label="Chân thuận" name="side_foot">
                                            <Select>
                                                <Option value="0">Hai chân</Option>
                                                <Option value="1">Chân trái</Option>
                                                <Option value="2">Chân phải</Option>
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>

                                    <Col span={24}>
                                        <FieldFloatItem label="Vị trí" name="position">
                                            <Select className="position" mode="multiple" allowClear>
                                                {POSITIONS.map((position) => (
                                                    <OptGroup key={position.id} label={position.label}>
                                                        {position.values.map((item) => (
                                                            <Option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        ))}
                                                    </OptGroup>
                                                ))}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
                                        <FormItem>
                                            <Row>
                                                <label>Kỹ năng</label>
                                            </Row>

                                            {rating.map((item, index) => {
                                                return (
                                                    <Row className="row" key={index}>
                                                        <Col span={8}>
                                                            <label>{item.name}</label>
                                                        </Col>
                                                        <Col span={16}>
                                                            <Rate
                                                                allowClear
                                                                allowHalf
                                                                value={+item.value}
                                                                onChange={(value) => handleChangeRating(value, item.id)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </FormItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Thành tích" name="achievement">
                                            <TextArea rows={2} />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Ghi chú" name="note">
                                            <TextArea name="note" rows={2} />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={20}>
                                        <Form.Item>
                                            <Row type="flex">
                                                <Col style={{ marginRight: 10 }}>
                                                    <Button type="default" onClick={handleBack}>
                                                        Quay lại
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" loading={loading} htmlType="submit">
                                                        Lưu
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {student_id && <TabPane tab="Quản lý khóa học" key="2" />}
        </Tabs>
    );
};

export default Detail;
