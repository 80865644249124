import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Button, Select, Row, Col } from "antd";
import moment from "moment";
import ReactExport from "react-data-export";

import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { saveBranch } from "@actions/branch";
import { removeAcl } from "@actions/acl";
import { getUserInfo } from "@utils/auth";
import { toArray } from "@utils";

import {
    api_getAllBranch,
    api_getAllBranchOptions,
    api_getAllTeacher,
    api_getAllStudent,
    api_getAllCourses,
    api_getAllInvoices,
} from "@api/globalApi";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let { Option } = Select;
function Header(props) {
    const { history } = props;
    const { Header } = Layout;
    const [dataTeacher, setDataTeacher] = useState([]);
    const [dataStudent, setDataStudent] = useState([]);
    const [dataCourses, setDataCourses] = useState([]);
    const [dataInvoices, setDataInvoices] = useState([]);
    const [dataBranch, setDataBranch] = useState([]);

    const dispatch = useDispatch();
    let user = getUserInfo();
    const [listBranch, setBranch] = useState([]);
    useEffect(() => {
        if (user.branch_id) {
            dispatch(saveBranch({ branch_id: user.branch_id }));
        }
        const _getAllBranchOptions = async () => {
            const res = await api_getAllBranchOptions({
                _orderBy: "id:asc",
            });
            if (res.status) {
                if (res.data) {
                    let arr = toArray(res.data);
                    setBranch(arr);
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranchOptions();
        _getListBranch();
        _getListTeacher();
        _getListStudent();
        _getListCourses();
        _getListInvoices();
    }, []);

    const _getListBranch = async () => {
        const res = await api_getAllBranch({ _paging: 0 });
        if (res.status) {
            setDataBranch(res.data);
        }
    };

    const _renderExportListBranch = () => {
        return (
            <ExcelSheet data={dataBranch} name="Chi nhánh">
                <ExcelColumn label="Tên chi nhánh" value="name" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Số sân thuê" value="number_yard" />
                <ExcelColumn label="Tiền thuê sân" value="yard_rent" />
                <ExcelColumn label="Trạng thái" value={(col) => (col.active == 1 ? "Đang mở" : "Đã đóng")} />
            </ExcelSheet>
        );
    };

    const _getListCourses = async () => {
        const res = await api_getAllCourses({ paging: 0 });
        if (res.status) {
            setDataCourses(res.data);
        }
    };

    const _renderExportListCourses = () => {
        return (
            <ExcelSheet data={dataCourses} name="Khoá học">
                <ExcelColumn label="Tên khoá học" value="name" />
                <ExcelColumn label="Học phí" value="tuition" />
                <ExcelColumn
                    label="Loại lớp"
                    value={(col) => {
                        if (col.type === "lopnangkhieu") {
                            return "Lớp năng khiếu";
                        }
                        if (col.type === "lopcongdong") {
                            return "Lớp cộng đồng";
                        }
                        return "Lớp cộng đồng";
                    }}
                />
                <ExcelColumn label="Số buổi học" value="duration" />
                <ExcelColumn
                    label="Giáo viên"
                    value={(col) => {
                        let teacher = dataTeacher.filter((item, index) => {
                            return item.id == col.teacher_id;
                        });
                        if (teacher[0]) {
                            return teacher[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn
                    label="Thứ 2"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_1.start_time && col.day_1.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_1.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_1.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 3"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_2.start_time && col.day_2.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_2.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_2.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 4"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_3.start_time && col.day_3.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_3.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_3.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 5"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_4.start_time && col.day_4.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_4.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_4.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 6"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_5.start_time && col.day_5.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_5.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_5.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 7"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_6.start_time && col.day_6.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_6.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_6.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Chủ nhật"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_7.start_time && col.day_7.end_time) {
                            infor = `Thời gian bắt đầu: ${moment(col.day_7.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Thời gian kết thúc: ${moment(col.day_7.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
            </ExcelSheet>
        );
    };

    const _getListTeacher = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setDataTeacher(res.data);
        }
    };

    const _renderExportListTeacher = () => {
        return (
            <ExcelSheet data={dataTeacher} name="Giáo viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender == 1 ? "Nam" : "Nữ")} />
                <ExcelColumn label="Hình ảnh" value="image" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Năm sinh" value="born_year" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Ghi chú" value="note" />
            </ExcelSheet>
        );
    };

    const _getListStudent = async () => {
        const res = await api_getAllStudent({ paging: 0 });
        if (res.status) {
            setDataStudent(res.data);
        }
    };

    const _renderExportListStudent = () => {
        return (
            <ExcelSheet data={dataStudent} name="Học viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Họ tên phụ huynh" value="parent_name" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender ? (col.gender == 1 ? "Nam" : "Nữ") : "")} />
                <ExcelColumn label="Hình ảnh" value="image" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Năm sinh" value="born_year" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn
                    label="Vị trí đá"
                    value={(col) => {
                        if (col.position) {
                            let result = "";
                            col.position.map((item) => (result += item + ","));
                            return result;
                        } else {
                            return "";
                        }
                    }}
                />
                <ExcelColumn label="Chiều cao" value="height" />
                <ExcelColumn label="Cân nặng" value="weight" />
                <ExcelColumn label="Quốc tịch" value="country" />
                <ExcelColumn label="Thành tích" value="achievement" />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn
                    label="Chân thuận"
                    value={(col) => {
                        if (col.side_foot == 1) {
                            return "Chân trái";
                        }
                        if (col.side_foot == 2) {
                            return "Chân phải";
                        }
                        if (col.side_foot == 0) {
                            return "Cả hai";
                        }
                        return "Chưa rõ";
                    }}
                />
            </ExcelSheet>
        );
    };

    const _getListInvoices = async () => {
        const res = await api_getAllInvoices({ paging: 0 });
        if (res.status) {
            setDataInvoices(res.data);
        }
    };

    const _renderExportListInvoices = () => {
        return (
            <ExcelSheet data={dataInvoices} name="Hoá đơn">
                <ExcelColumn label="Mã hoá đơn" value="code" />
                <ExcelColumn label="Họ tên học viên" value="name" />
                <ExcelColumn label="Số tiền" value="amount" />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Loại hoá đơn"
                    value={(col) => {
                        if (col.type == 1) {
                            return "Thu";
                        }
                        if (col.type == 0) {
                            return "Chi";
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Số điện thoại học viên" value="phone" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Nội dung" value="content" />
                <ExcelColumn label="Ghi chú" value="note" />
            </ExcelSheet>
        );
    };

    const redirectTo = (route) => {
        props.history.replace(route);
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <a onClick={() => redirectTo("/profile")}>Thiết lập</a>
            </Menu.Item>
            <Menu.Item onClick={() => logOut()}>Đăng xuất</Menu.Item>
        </Menu>
    );

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        localStorage.removeItem("roles");
        localStorage.removeItem("acl");
        dispatch(saveBranch({ branch_id: null }));
        dispatch(removeAcl());
        history.push("/login");
    };

    const handleChangeType = (value) => {
        dispatch(saveBranch({ branch_id: value }));
        sessionStorage.clear();
    };

    return (
        <Header className="layout-header">
            <Row className="container-header">
                <Col className="wrap-branch" style={{ display: "flex", alignItems: "center" }}>
                    <Select
                        defaultValue={user.branch_id || ""}
                        onChange={handleChangeType}
                        placeholder="Chọn chi nhánh"
                        style={{ marginLeft: 10, width: 200 }}
                    >
                        <Option key="2000" value="">
                            Tất cả chi nhánh
                        </Option>
                        {listBranch &&
                            listBranch.map((item, i) => {
                                return (
                                    <Option key={i} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                    </Select>
                    {/* <Button style={{ marginLeft: 10 }}>Thống kê</Button> */}
                    <div style={{ marginLeft: 10 }}>
                        <ExcelFile element={<Button style={{ marginLeft: 10 }}>Xuất file</Button>}>
                            {_renderExportListTeacher()}
                            {_renderExportListStudent()}
                            {_renderExportListCourses()}
                            {_renderExportListInvoices()}
                            {_renderExportListBranch()}
                        </ExcelFile>
                    </div>
                </Col>
                <Col style={{ padding: "0 24px" }}>
                    Xin chào, {user.username || user.name}
                    <Dropdown overlay={menu}>
                        <Button icon={<UserOutlined />} shape="circle-outline" className="icon-user" />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    );
}

export default Header;
