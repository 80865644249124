import api from "@api";

export const api_getAllCourse = (param) => {
    const url = `/course`;
    return api.get(url, {
        params: {
            _page: param.page,
            _perPage: 10,
            _filter: param.filters,
        },
    });
};

export const api_createCourse = (param) => {
    const url = `course`;
    return api.post(url, param);
};

export const api_getOneCourse = (param) => {
    const url = `course/${param}`;
    return api.get(url);
};

export const api_updateCourse = (param) => {
    const url = `course/${param.id}`;
    return api.put(url, param.data);
};

export const api_getStudentsOfCourse = (params) => {
    const url = `student/course`;
    return api.get(url, {
        params,
    });
};
