import api from "@api";

export const api_getAllPitch = (param) => {
    const url = `/yard`;
    return api.get(url, {
        params: {
            _page: param.page,
            _filter: param.filters,
        },
    });
};

export const api_createPitch = (param) => {
    const url = `yard`;
    return api.post(url, param);
};

export const api_getOnePitch = (param) => {
    const url = `yard/${param}`;
    return api.get(url);
};

export const api_updatePitch = (param) => {
    const url = `yard/${param.id}`;
    return api.put(url, param.data);
};
