export const UPDATE_MODAL = "UPDATE_MODAL";

export function updateDataModal(data) {
    return {
        type: UPDATE_MODAL,
        payload: {
            data: data,
        },
    };
}
