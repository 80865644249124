import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Select, Upload, DatePicker, Row, Col, message, Tabs } from "antd";
import moment from "moment";

import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "@components/CenterArea";
import { api_createTeacher, api_getOneTeacher, api_updateTeacher } from "./apiTeacher";
import TabCourse from "./TabCourse";
import { api_uploadImage, api_deleteImage } from "@api/globalApi";

const { TabPane } = Tabs;

const Detail = (props) => {
    const { history } = props;
    const [form] = NewForm.useForm();
    const [listCourse, setListCourse] = useState([]);
    const [image, setImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);
    const { id } = props.match.params;

    const onFinish = async (values) => {
        const sendData = async (imageurl) => {
            if (id) {
                const res = await api_updateTeacher({
                    id,
                    data: {
                        ...values,
                        birthday: values.birthday ? moment(values.birthday).format("YYYY-MM-DD") : null,
                        image: imageurl ? imageurl : null,
                    },
                });
                if (res.status) {
                    message.success("Cập nhật thành công");
                    history.push("/teacher");
                } else {
                    if (res.message) {
                        message.error(res.message);
                    } else {
                        message.error("Cập nhật thất bại, vui lòng thử lại sau");
                    }
                }
            } else {
                const res = await api_createTeacher({
                    ...values,
                    birthday: values.birthday ? moment(values.birthday).format("YYYY-MM-DD") : null,
                    image: imageurl ? imageurl : null,
                    status: "1",
                });
                if (res.status) {
                    message.success("Tạo thành công");
                    history.push("/teacher");
                } else {
                    if (res.message) {
                        message.error(res.message);
                    } else {
                        message.error("Tạo thất bại, vui lòng thử lại sau");
                    }
                }
            }
        };
        if (image) {
            if (typeof image !== "string") {
                const resImage = await api_uploadImage(image);
                if (resImage.status) {
                    sendData(resImage.result.file);
                }
            } else sendData(image);
        } else {
            if (oldImage) {
                api_deleteImage(oldImage);
            }
            sendData();
        }
    };

    const uploadButton = (
        <div>
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const _handleChangeImage = ({ fileList }) => {
        if (fileList.length == 0) {
            if (typeof image === "string") {
                setOldImage(image);
            }
            setImage(null);
        } else {
            setImage(fileList[0]);
        }
    };
    useEffect(() => {
        if (id) {
            _getTeacher();
        } else {
            form.setFieldsValue({
                status: "1",
                gender: 1,
            });
        }
        return () => {
            if (!history.location.pathname.includes("/teacher")) {
                sessionStorage.clear();
            }
        };
    }, []);
    const _getTeacher = async () => {
        const res = await api_getOneTeacher(id);
        if (res.status) {
            setListCourse(res.data.courses);
            setImage(res.data.image);
            const { name, email, birthday, gender, address, phone, status, note } = res.data;
            form.setFieldsValue({
                name,
                email,
                birthday: birthday ? moment(birthday, "YYYY-MM-DD") : null,
                gender,
                address,
                phone,
                status,
                note,
            });
        }
    };
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin giáo viên" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} md={6}>
                                <Upload
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("Ok");
                                        }, 0);
                                    }}
                                    listType="picture-card"
                                    fileList={
                                        image
                                            ? image.uid
                                                ? [image]
                                                : [
                                                      {
                                                          uid: "1",
                                                          name: "image.png",
                                                          status: "done",
                                                          url: image,
                                                      },
                                                  ]
                                            : []
                                    }
                                    // onPreview={this.handlePreview}
                                    onChange={_handleChangeImage}
                                >
                                    {!image && uploadButton}
                                </Upload>
                            </Col>
                            <Col xs={24} md={18}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <FieldFloatItem
                                            rules={[{ required: true, message: "Nhập họ tên!" }]}
                                            label="Họ tên"
                                            name="name"
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem label="Email" name="email">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Giới tính" name="gender">
                                            <Radio.Group>
                                                <Radio value={1}>Nam</Radio>
                                                <Radio value={0}>Nữ</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem
                                            rules={[{ required: true, message: "Nhập số điện thoại!" }]}
                                            label="Điện thoại"
                                            name="phone"
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem label="Sinh nhật" name="birthday">
                                            <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Địa chỉ" name="address">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Ghi chú" name="note">
                                            <Input.TextArea rows={3} />
                                        </FieldFloatItem>
                                    </Col>
                                    {id && (
                                        <Col xs={24}>
                                            <FieldFloatItem label="Trạng thái" name="status">
                                                <Select>
                                                    <Select.Option value="1">Đang dạy</Select.Option>
                                                    <Select.Option value="2">Đã nghỉ</Select.Option>
                                                </Select>
                                            </FieldFloatItem>
                                        </Col>
                                    )}
                                    <Col xs={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {id && (
                <TabPane tab="Khoá học của giáo viên" key="2">
                    <TabCourse history={history} listCourse={listCourse} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default Detail;
