import _ from "lodash";

import { GET_ALL_MENU, UPDATE_NAVPATH, TOGGLE_MENU } from "@actions/menu";

const initialState = {
    items: [],
    navpath: [],
    isOpened: localStorage.getItem("isOpenedMenu"),
    num: 0,
};

const menu = (state = initialState, action = {}) => {
    switch (action.type) {
        case TOGGLE_MENU:
            return Object.assign({}, state, { isOpened: action.payload.data });
        case GET_ALL_MENU:
            var menus = action.payload.data.menus;
            var menuItems = Object.keys(menus).map(function (k) {
                return menus[k];
            });
            return Object.assign({}, state, { items: menuItems });
        case UPDATE_NAVPATH:
            var navpath = [],
                tmpOb,
                tmpKey,
                child;
            if (Array.isArray(action.payload.data)) {
                action.payload.data.reverse().map((item) => {
                    if (item.indexOf("sub") != -1) {
                        tmpKey = item.replace("sub", "");
                        tmpOb = _.find(state.items, function (o) {
                            return o.key == tmpKey;
                        });
                        child = tmpOb.child;
                        navpath.push({
                            key: tmpOb.key,
                            name: tmpOb.name,
                        });
                    }
                    if (item.indexOf("menu") != -1) {
                        tmpKey = item.replace("menu", "");
                        if (child) {
                            tmpOb = _.find(child, function (o) {
                                return o.key == tmpKey;
                            });
                            navpath.push({
                                key: tmpOb.key,
                                name: tmpOb.name,
                            });
                        } else {
                            tmpOb = _.find(state.items, function (o) {
                                return o.key == tmpKey;
                            });
                            navpath.push({
                                key: tmpOb.key,
                                name: tmpOb.name,
                            });
                        }
                    }
                });
            }
            return Object.assign({}, state, {
                currentIndex: action.payload.key * 1,
                navpath: navpath,
            });
        default:
            return state;
    }
};

export default menu;
