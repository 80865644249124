import api from "@api";
export const api_createBranch = (param) => {
    const url = `branch`;
    return api.post(url, param);
};
export const api_getOneBranch = (branch_id) => {
    const url = `branch/${branch_id}`;
    return api.get(url);
};
export const api_updateBranch = (branch_id, param) => {
    const url = `branch/${branch_id}`;
    return api.put(url, param);
};

export const api_getDeleteBranch = (branch_id) => {
    const url = `branch/inactive/${branch_id}`;
    return api.post(url);
};
