import React from "react";
import "./index.scss";

const ContextMenu = ({ visible, record, x, y, menu }) =>
    visible && (
        <ul className="context-menu-popup" style={{ left: `${x}px`, top: `${y}px` }}>
            {menu.map(
                (item, index) =>
                    item.showItem(record) && (
                        <li key={index} onClick={() => item.onClick(record)}>
                            {item.render(record)}
                        </li>
                    )
            )}
        </ul>
    );

export default ContextMenu;
