import React from "react";

export const createColumnConfig = (props) => {
    const { pagination } = props;

    let defaultColumn = [
        {
            no: 1,
            title: <span className="dragHandler">STT</span>,
            fixed: "left",
            width: 80,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            no: 2,
            title: <span className="dragHandler">Họ tên</span>,
            dataIndex: "name",
            width: 200,
            align: "center",
        },
        {
            no: 3,
            title: <span className="dragHandler">Username</span>,
            dataIndex: "username",
            width: 200,
            align: "center",
        },
        {
            no: 4,
            title: <span className="dragHandler">Trạng thái</span>,
            dataIndex: "active",
            width: 200,
            align: "center",
            render: (text, record) => <span>{record.active == 1 ? "Kích hoạt" : "Khóa"}</span>,
        },
        {
            no: 5,
            title: <span className="dragHandler">Quyền</span>,
            dataIndex: "role_name",
            width: 200,
            align: "center",
            render: (text, record) => <span>{(record.role_name || []).join(", ")}</span>,
        },
    ];

    return defaultColumn;
};
