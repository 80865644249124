import React, { useState, useEffect } from "react";
import { Button, Form, Input, Row, Col, notification, Tabs, Select, DatePicker } from "antd";

import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { validateObject } from "@utils";

import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "../../components/CenterArea";
import { getStudentLesson, updateStudentLesson } from "./apiStudent";

let { TextArea } = Input;
let { TabPane } = Tabs;
let { Option } = Select;

const LessonDetail = (props) => {
    let history = useHistory();
    let { student_id, course_id, lesson_id } = useParams();
    let { pathname } = useLocation();
    const [form] = NewForm.useForm();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [listStatus, setListStatus] = useState([
        { id: "1", name: "Sẵn sàng" },
        { id: "2", name: "Hoàn thành" },
        { id: "3", name: "Xin nghỉ" },
        { id: "4", name: "Bảo lưu" },
    ]);
    const [statusId, setStatusId] = useState(null);
    const [statusIdInit, setStatusIdInit] = useState(null);

    const handleBack = () => {
        history.goBack();
    };

    const onSubmit = () => {
        setLoading(true);

        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateObject(values);
                dataSubmit.learn_date = dataSubmit.learn_date ? dataSubmit.learn_date.format("YYYY-MM-DD") : null;
                if (lesson_id) {
                    if (statusId == 3) {
                        dataSubmit.date_make_up_lesson = dataSubmit.date_make_up_lesson
                            ? dataSubmit.date_make_up_lesson.format("YYYY-MM-DD")
                            : null;
                    }
                    let res = await updateStudentLesson(lesson_id, dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                        handleBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const initFetch = async () => {
        const res = await getStudentLesson(lesson_id);
        if (res.status) {
            form.setFieldsValue({
                name: res.data.name,
                learn_date: res.data.learn_date ? moment(res.data.learn_date, "YYYY-MM-DD") : null,
                status: res.data?.status?.toString(),
                note: res.data.note,
                date_make_up_lesson: moment(new Date(), "YYYY-MM-DD").add(1, "days"),
            });
            setStatusId(res.data?.status?.toString());
            setStatusIdInit(res.data?.status?.toString());
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    const onFocusStatus = () => {
        let arr = [];
        let arr_1 = listStatus.filter((item) => item.id == statusIdInit)[0];
        if (statusIdInit == 3) {
            arr = listStatus.filter((item) => item.id == 1);
        } else {
            arr = listStatus.filter((item) => item.id == 3);
        }
        arr.push({ ...arr_1 });
        console.log("focus", arr_1, arr);
        setListStatus(arr);
    };

    const onChangeStatus = (value) => {
        setStatusId(value);
    };

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        if (lesson_id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            setVisible(true);
        }
    }, []);

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin buổi học" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Tên buổi học" name="name">
                                    <Input disabled={true} />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Ngày học" name="learn_date">
                                    <DatePicker
                                        disabled={!pathname.includes("/student-lesson/")}
                                        style={{ width: "100%" }}
                                        placeholder={null}
                                        format="DD/MM/YYYY"
                                    />
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Trạng thái" name="status">
                                    <Select
                                        onFocus={onFocusStatus}
                                        onChange={onChangeStatus}
                                        disabled={
                                            moment(new Date(), "YYYY-MM-DD").isSameOrAfter(
                                                form.getFieldValue("learn_date"),
                                                "YYYY-MM-DD",
                                                "day"
                                            ) || statusIdInit == 3
                                        }
                                    >
                                        {listStatus &&
                                            listStatus.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id.toString()}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        {statusId == "3" && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={24}>
                                    <FieldFloatItem label="Ngày học lại" name="date_make_up_lesson">
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            style={{ width: "100%" }}
                                            placeholder={null}
                                            format="DD/MM/YYYY"
                                        />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Ghi chú" name="note">
                                    <TextArea name="note" rows={2} />
                                </FieldFloatItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row type="flex">
                                        <Col style={{ marginRight: 10 }}>
                                            <Button type="default" onClick={handleBack}>
                                                Quay lại
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button type="primary" loading={loading} htmlType="submit">
                                                Lưu
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default LessonDetail;
