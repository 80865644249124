import { SAVE_BRANCH } from "@actions/branch";

const initialState = {
    branch_id: null,
};

export default function branch(state = initialState, action = {}) {
    switch (action.type) {
        case SAVE_BRANCH:
            return action.payload.data;
        default:
            return state;
    }
}
