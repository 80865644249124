import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Select, TimePicker, Row, Col, message, Tabs } from "antd";
import moment from "moment";

import CenterArea from "@components/CenterArea";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { validateObject, numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { api_createCourse, api_getOneCourse, api_updateCourse, api_getStudentsOfCourse } from "./apiCourse";
import { api_getAllTeacher, api_getAllBranchOptions } from "@api/globalApi";
import { InputCurrency } from "@components/Input";
import TabStudent from "./TabStudent";
import { getRole } from "@utils/auth";

const { TabPane } = Tabs;
const { Option } = Select;

const Detail = (props) => {
    const { history } = props;
    const [form] = NewForm.useForm();
    const user = useSelector((state) => state.branch);
    const [listTeacher, setListTeacher] = useState([]);
    const [listBranch, setBranch] = useState([]);
    const acl = useSelector((state) => state.acl.acl);
    const { id } = props.match.params;
    const onFinish = async (values) => {
        let dataSubmit = createSubmitData(values);
        if (id) {
            const res = await api_updateCourse({
                id,
                data: { ...dataSubmit },
            });
            if (res.status) {
                message.success("Cập nhật thành công");
                history.push("/course");
            } else {
                message.error("Cập nhật thất bại, vui lòng thử lại sau");
            }
        } else {
            const res = await api_createCourse({
                ...dataSubmit,
            });
            if (res.status) {
                message.success("Tạo thành công");
                history.push("/course");
            } else {
                message.error("Tạo thất bại, vui lòng thử lại sau");
            }
        }
    };
    useEffect(() => {
        _getAllTeacher();
        if (id) {
            _getCourse();
        } else {
            form.setFieldsValue({
                status: 1,
                gender: 1,
                branch_id: user.branch_id,
                duration: 8,
            });
        }
        const _getAllBranch = async () => {
            let params = { params: { _orderBy: "id:asc" } };
            const res = await api_getAllBranchOptions(params);
            if (res.status) {
                if (res.data) {
                    setBranch(toArray(res.data));
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        return () => {
            if (!history.location.pathname.includes("/course")) {
                sessionStorage.clear();
            }
        };
    }, []);
    const _getCourse = async () => {
        const res = await api_getOneCourse(id);
        if (res.status) {
            const { name, status, note, code, teacher_id, tuition, type, duration, branch_id } = res.data;
            form.setFieldsValue({
                name,
                status,
                note,
                code,
                teacher_id,
                tuition: tuition ? numberWithCommas(tuition) : 0,
                type,
                begin_date: res.data.begin_date ? moment(res.data.begin_date, "DD-MM-YYYY") : null,
                end_date: res.data.end_date ? moment(res.data.end_date, "DD-MM-YYYY") : null,
                duration,
                branch_id,
            });
            const arr = ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"];
            for (let i = 0; i < arr.length; i++) {
                form.setFieldsValue({
                    [arr[i]]: {
                        start_time: res.data[arr[i]].start_time ? moment(res.data[arr[i]].start_time, "HH:mm") : null,
                        end_time: res.data[arr[i]].end_time ? moment(res.data[arr[i]].end_time, "HH:mm") : null,
                    },
                });
            }
        }
    };

    const _getAllTeacher = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setListTeacher(res.data);
        }
    };

    const createSubmitData = (values) => {
        let data = validateObject(values);

        data.begin_date = data?.begin_date?.format("YYYY-MM-DD") || null;
        data.end_date = data?.end_date?.format("YYYY-MM-DD") || null;

        ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"].forEach((key) => {
            data[[key]] = {
                start_time: data?.[key]?.start_time?.format("HH:mm:ss") || null,
                end_time: data?.[key]?.end_time?.format("HH:mm:ss") || null,
                teacher_id: data?.[key]?.teacher_id || null,
            };
        });

        data.tuition = numberWithoutCommas(data.tuition);
        data.status_id = data.status_id || 1;

        return data;
    };

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin khoá học" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    // rules={[{ required: true, message: "Nhập mã khoá học!" }]}
                                    label="Mã khoá học"
                                    name="code"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Nhập tên khoá học!" }]}
                                    label="Tên khoá học"
                                    name="name"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Chọn loại lớp!" }]}
                                    label="Loại lớp"
                                    name="type"
                                >
                                    <Select>
                                        <Option value="lopcongdong">Lớp cộng đồng</Option>
                                        <Option value="lopnangkhieu">Lớp năng khiếu</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Nhập học phí!" }]}
                                    label="Học phí"
                                    name="tuition"
                                >
                                    <InputCurrency />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Chọn giáo viên!" }]}
                                    label="Giáo viên"
                                    name="teacher_id"
                                >
                                    <Select>
                                        {listTeacher.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={index}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                        {/* <Select.Option value="1">Đang dạy</Select.Option>
                                <Select.Option value="2">Đã nghỉ</Select.Option> */}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem label="Trạng thái" name="status">
                                    <Select>
                                        <Option value={1}>Đang mở</Option>
                                        <Option value={2}>Đã đóng</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            {/* <Col xs={24} lg={12}>
                                <FieldFloatItem label="Ngày bắt đầu" name="begin_date">
                                    <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem label="Ngày kết thúc" name="end_date">
                                    <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
                                </FieldFloatItem>
                            </Col> */}
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Nhập số buổi!" }]}
                                    label="Số buổi"
                                    name="duration"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Chi nhánh"
                                    name="branch_id"
                                    rules={[{ required: true, message: "Chọn chi nhánh!" }]}
                                >
                                    <Select
                                        // onChange={handleChangeType}
                                        disabled={id ? true : false}
                                    >
                                        {listBranch &&
                                            listBranch.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24}>
                                <Row>
                                    <Col xs={24} lg={3}>
                                        Lịch học:
                                    </Col>
                                    <Col xs={24}>
                                        <Row
                                            gutter={{
                                                xs: 8,
                                                sm: 8,
                                                md: 16,
                                            }}
                                        >
                                            <Col xs={24} sm={24} md={2}></Col>
                                            <Col xs={24} sm={24} md={9}>
                                                <Row
                                                    gutter={{
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 16,
                                                    }}
                                                >
                                                    <Col span={12}>
                                                        <div>Bắt đầu</div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div>Kết thúc</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} lg={24}>
                                        {[
                                            ["1", "T2"],
                                            ["2", "T3"],
                                            ["3", "T4"],
                                            ["4", "T5"],
                                            ["5", "T6"],
                                            ["6", "T7"],
                                            ["7", "CN"],
                                        ].map((item, index) => {
                                            return (
                                                <Row
                                                    gutter={{
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 16,
                                                    }}
                                                    key={index}
                                                >
                                                    <Col xs={24} sm={24} md={2}>
                                                        <label className="item-label">{item[1]}</label>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={9}>
                                                        <Row
                                                            gutter={{
                                                                xs: 8,
                                                                sm: 8,
                                                                md: 16,
                                                            }}
                                                        >
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    // label="Bắt đầu"
                                                                    name={[`day_${item[0]}`, "start_time"]}
                                                                >
                                                                    <TimePicker
                                                                        placeholder="Chọn thời gian"
                                                                        format="HH:mm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    // label="Kết thúc"
                                                                    name={[`day_${item[0]}`, "end_time"]}
                                                                >
                                                                    <TimePicker
                                                                        placeholder="Chọn thời gian"
                                                                        format="HH:mm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {/* <Col xs={24} sm={24} md={4}>
                                                <FieldFloatItem
                                                    label={t("quick_select")}
                                                    name={[`day_${item[0]}`, "autoShift"]}
                                                >
                                                    <Select
                                                        placeholder={null}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(value) =>
                                                            handleChangeShiftOnRecord(`day_${item[0]}`, value)
                                                        }
                                                    >
                                                        <Option value="bochon">Bỏ chọn</Option>
                                                        <Option value="08:30:00-10:00:00">8h30 - 10h00</Option>
                                                        <Option value="08:30:00-11:30:00">8h30 - 11h30</Option>
                                                        <Option value="09:00:00-11:15:00">9h00 - 11h15</Option>
                                                        <Option value="09:00:00-12:00:00">9h00 - 12h00</Option>
                                                        <Option value="10:30:00-12:00:00">10h30 - 12h00</Option>
                                                        <Option value="14:00:00-15:30:00">14h00 - 15h30</Option>
                                                        <Option value="14:00:00-16:15:00">14h00 - 16h15</Option>
                                                        <Option value="14:00:00-17:00:00">14h00 - 17h00</Option>
                                                        <Option value="16:00:00-17:30:00">16h00 - 17h30</Option>
                                                        <Option value="18:00:00-19:30:00">18h00 - 19h30</Option>
                                                        <Option value="18:00:00-21:00:00">18h00 - 21h00</Option>
                                                        <Option value="19:35:00-21:05:00">19h35 - 21h05</Option>
                                                    </Select>
                                                </FieldFloatItem>
                                            </Col> */}
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ marginTop: 15 }} xs={24}>
                                <FieldFloatItem label="Ghi chú" name="note">
                                    <Input.TextArea rows={3} />
                                </FieldFloatItem>
                            </Col>
                            {acl.hasAllowed(getRole(), "course", "update") && (
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            {id ? "Cập nhật" : "Tạo mới"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {id && (
                <TabPane tab="Học viên của khoá" key={2}>
                    <TabStudent id={id} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default Detail;
