export function toJson(input) {
    return JSON.stringify(input);
}

export function fromJson(input) {
    return JSON.parse(input);
}

export default {
    formItemLayout: {
        labelCol: {
            xs: { span: 24 },
            lg: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            lg: { span: 16 },
        },
    },
    tailFormItemLayout: {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 14,
                offset: 6,
            },
        },
    },
};
