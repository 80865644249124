import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Resizable } from "react-resizable";
import ReactDragListView from "react-drag-listview";
import "./index.scss";

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const CustomTable = (props) => {
    const { columns, tableName, ...rest } = props;

    const components = {
        header: {
            cell: ResizableTitle,
        },
    };

    const [customColumns, setCustomColumns] = useState([]);

    const handleResize = (index) => (e, { size }) => {
        const nextColumns = [...customColumns];
        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };
        const columnsStorage = nextColumns.map((item) => ({ no: item.no, width: item.width }));
        localStorage.setItem(tableName, JSON.stringify(columnsStorage));
        setCustomColumns(nextColumns);
    };

    const renderColumns = () => {
        let newColumns = [...customColumns];
        newColumns = newColumns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => {
                return {
                    width: column.width,
                    onResize: handleResize(index),
                };
            },
        }));
        return newColumns;
    };

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...customColumns];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            const columnsStorage = columns.map((item) => ({ no: item.no, width: item.width }));
            localStorage.setItem(tableName, JSON.stringify(columnsStorage));
            setCustomColumns(columns);
        },
        nodeSelector: "th",
        handleSelector: ".dragHandler",
        ignoreSelector: "react-resizable-handle",
    };

    useEffect(() => {
        if (columns.length !== 0) {
            const columnsStorage = JSON.parse(localStorage.getItem(tableName));
            if (columnsStorage && columnsStorage.length !== 0) {
                let newColumns = [];
                columnsStorage.forEach((item) => {
                    columns.forEach((subItem) => {
                        if (item.no == subItem.no) {
                            subItem.width = item.width;
                            newColumns.push(subItem);
                        }
                    });
                });
                setCustomColumns(newColumns);
            } else {
                setCustomColumns(columns);
            }
        }
    }, [columns]);

    return (
        <ReactDragListView.DragColumn {...dragProps}>
            <Table {...rest} components={components} columns={renderColumns()} />
        </ReactDragListView.DragColumn>
    );
};

export default CustomTable;
