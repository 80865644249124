import Acl from "acljs";

class AclProcessor {
    constructor() {
        this.acl = new Acl();
    }

    getAcl() {
        this.acl.hasAllowed = function (roles, resource, privilege) {
            return roles !== undefined && roles[resource] !== undefined && roles[resource][privilege] !== undefined;
        };
        return this.acl;
    }
}

export default AclProcessor;
