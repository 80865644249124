import { useEffect, useRef, useState } from "react";

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const callbackRef = useRef(null);

    const setStateCallback = (setStateAction, callback) => {
        callbackRef.current = callback;
        setState(setStateAction);
    };

    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current?.(state);
            callbackRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
}

export default useStateCallback;
