import api from "@api";

export const getListInvoices = (params) => {
    const url = "/invoice";
    return api.get(url, params);
};

export const getInvoice = (invoice_id, params) => {
    const url = "/invoice/" + invoice_id;
    return api.get(url, params);
};

export const createInvoice = (dataInvoice) => {
    const url = "/invoice";
    return api.post(url, dataInvoice);
};

export const updateInvoice = (invoice_id, dataInvoice) => {
    const url = "/invoice/" + invoice_id;
    return api.put(url, dataInvoice);
};

export const removeInvoice = (invoice_id) => {
    const url = "/invoice/" + invoice_id;
    return api.delete(url);
};
