import React, { useState, useEffect } from "react";
import { Button } from "antd";
import ReactExport from "react-data-export";
import { api_getOneCourse } from "./apiCourse";
import { api_getOneTeacher } from "../Teacher/apiTeacher";
import { toArray } from "@utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let POSITION = [
    {
        id: "GK",
        name: "Thủ môn",
    },
    {
        id: "SW",
        name: "Trung vệ quét",
    },
    {
        id: "RB",
        name: "Hậu vệ biên phải",
    },
    {
        id: "LB",
        name: "Hậu vệ biên trái",
    },
    {
        id: "LWB",
        name: "Hậu vệ tấn công trái",
    },
    {
        id: "RWB",
        name: "Hậu vệ tấn công phải",
    },
    {
        id: "CB",
        name: "Trung vệ",
    },
    {
        id: "CM",
        name: "Tiền vệ trung tâm",
    },
    {
        id: "CDM",
        name: "Tiền vệ lùi",
    },
    {
        id: "CAM",
        name: "Tiền vệ tấn công",
    },
    {
        id: "LM",
        name: "Tiền vệ cánh trái",
    },
    {
        id: "RM",
        name: "Tiền vệ cánh phải",
    },
    {
        id: "LDM",
        name: "Tiền vệ phòng ngự lệch trái",
    },
    {
        id: "RDM",
        name: "Tiền vệ phòng ngự lệch phải",
    },
    {
        id: "ST",
        name: "Tiền đạo cắm",
    },
    {
        id: "CF",
        name: "Hộ công",
    },
    {
        id: "LW",
        name: "Tiền đạo cánh trái",
    },
    {
        id: "RW",
        name: "Tiền đạo cánh phải",
    },
    {
        id: "RF",
        name: "Tiền đạo lệch phải",
    },
    {
        id: "LF",
        name: "Tiền đạo lệch trái",
    },
];

function ExportStudent(props) {
    let { data, idCourse } = props;
    const [multiDataSet, setMultiDataSet] = useState([]);
    const [dataCourse, setDataCourse] = useState([]);
    const [dataTeacher, setDataTeacher] = useState([]);
    const [dayCourse, setDayCourse] = useState("");

    const convertData = () => {
        if (data) {
            let dataTmp = [];
            let t = [];
            for (let i = 0; i < data.length; i++) {
                // if (data[i].status == "1") {
                let r = data[i].student.data;
                data.statusCourseOfStudent = data[i].status;
                t.push(r);
                // }
            }
            for (let i = 0; i < t.length; i++) {
                let sideFoot = "";
                let positionResult = [];
                let gender = t[i].gender == 1 ? "Nam" : "Nữ";
                let status = t[i].statusCourseOfStudent == "1" ? "Đang học" : "Bảo lưu";
                if (t[i].side_foot == 1) {
                    sideFoot = "Chân trái";
                } else if (t[i].side_foot == 2) {
                    sideFoot = "Chân phải";
                } else if (t[i].side_foot == 0) {
                    sideFoot = "Cả hai";
                } else {
                    sideFoot = "Chưa rõ";
                }
                if (t[i].position && t[i].position.length > 0) {
                    for (let j = 0; j < t[i].position.length; j++) {
                        for (let k = 0; k < POSITION.length; k++) {
                            if (t[i].position[j] == POSITION[k].id) {
                                positionResult.push(POSITION[k].name);
                            }
                        }
                    }
                }

                let m = [
                    { value: t[i].name || "" },
                    { value: status },
                    { value: gender },
                    { value: t[i].phone || "" },
                    { value: sideFoot },
                    { value: positionResult.join(",") },
                    { value: t[i].achievement || "" },
                    { value: t[i].address || "" },
                    { value: t[i].note || "" },
                ];
                dataTmp.push(m);
            }
            setMultiDataSet([
                {
                    columns: [
                        { title: "Họ tên", width: { wpx: 150 } }, //pixels width
                        { title: "Trạng thái", width: { wpx: 100 } }, //char width
                        { title: "Giới tính", width: { wpx: 100 } },
                        { title: "SDT", width: { wpx: 120 } },
                        { title: "Chân thuận", width: { wpx: 100 } },
                        { title: "Vị trí", width: { wpx: 150 } },
                        { title: "Thành tích", width: { wpx: 150 } },
                        { title: "Địa chỉ", width: { wpx: 200 } },
                        { title: "Ghi chú", width: { wpx: 200 } },
                    ],
                    data: dataTmp,
                },
            ]);
        }
    };
    useEffect(() => {
        getInfoCourse();
        if (data) {
            convertData();
        }
    }, [data]);

    const getInfoCourse = async () => {
        const resCourse = await api_getOneCourse(idCourse);
        if (resCourse.status) {
            const resTeacher = await api_getOneTeacher(resCourse.data.teacher_id);
            setDataCourse(resCourse.data);
            setDataTeacher(resTeacher.data);
            let dayLesson = [];
            let result = [];
            let arr1 = [
                ["day_1", "T2"],
                ["day_2", "T3"],
                ["day_3", "T4"],
                ["day_4", "T5"],
                ["day_5", "T6"],
                ["day_6", "T7"],
                ["day_7", "CN"],
            ];
            const arr = ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"];
            for (let i = 0; i < arr.length; i++) {
                if (resCourse.data[arr[i]].start_time && resCourse.data[arr[i]].end_time) {
                    dayLesson.push(arr[i]);
                }
            }
            for (let j = 0; j < dayLesson.length; j++) {
                for (let i = 0; i < arr1.length; i++) {
                    if (dayLesson[j] == arr1[i][0]) {
                        result.push(arr1[i][1]);
                    }
                }
            }
            setDayCourse(result.join("_"));
        }
    };

    const getFileName = () => {
        let result = [];
        if (dataCourse) {
            result.push(dataCourse.name);
        }
        if (dataTeacher) {
            result.push(dataTeacher.name);
        }
        if (dayCourse) {
            result.push(dayCourse);
        }
        return result.join("_");
    };

    return (
        <span style={{ marginLeft: 15 }}>
            <ExcelFile element={<Button>Xuất danh sách học viên</Button>} filename={getFileName()} fileExtension="xlsx">
                <ExcelSheet dataSet={multiDataSet} name={"Danh sách học viên"} />
            </ExcelFile>
        </span>
    );
}

export default ExportStudent;
