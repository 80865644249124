export function createAcl() {
    return {
        type: "CREATE_ACL",
    };
}

export const saveAcl = (payload) => {
    return {
        type: "SAVE_ACL",
        payload,
    };
};

export const removeAcl = () => {
    return {
        type: "REMOVE_ACL",
    };
};
