export function focusSubMenu(pathname) {
    var activeKey = null;
    if (pathname === "/dashboard") {
        activeKey = "menu1";
    } else if (pathname === "/student-money") {
        activeKey = "menu1";
    } else if (pathname === "/student") {
        activeKey = "menu2";
    } else if (pathname === "/add-student") {
        activeKey = "menu2";
    } else if (/student-detail/.test(pathname)) {
        activeKey = "menu2";
    } else if (/student-course/.test(pathname)) {
        activeKey = "menu2";
    } else if (/student-lesson/.test(pathname)) {
        activeKey = "menu2";
    } else if (pathname === "/teacher") {
        activeKey = "menu4";
    } else if (/teacher-detail/.test(pathname)) {
        activeKey = "menu4";
    } else if (pathname === "/invoice") {
        activeKey = "menu5";
    } else if (pathname === "/create-invoice") {
        activeKey = "menu5";
    } else if (/invoice-detail/.test(pathname)) {
        activeKey = "menu5";
    } else if (pathname === "/course") {
        activeKey = "menu6";
    } else if (/course-detail/.test(pathname)) {
        activeKey = "menu6";
    } else if (pathname === "/account") {
        activeKey = "menu7";
    } else if (/account-detail/.test(pathname)) {
        activeKey = "menu7";
    } else if (/add-detail/.test(pathname)) {
        activeKey = "menu7";
    } else if (pathname === "/role") {
        activeKey = "menu8";
    } else if (/role-detail/.test(pathname)) {
        activeKey = "menu8";
    } else if (/add-role/.test(pathname)) {
        activeKey = "menu8";
    } else if (pathname === "/branch") {
        activeKey = "menu9";
    } else if (/branch-detail/.test(pathname)) {
        activeKey = "menu9";
    } else if (/add-branch/.test(pathname)) {
        activeKey = "menu9";
    } else if (pathname === "/pitch") {
        activeKey = "menu10";
    } else if (/pitch-detail/.test(pathname)) {
        activeKey = "menu10";
    } else if (/add-pitch/.test(pathname)) {
        activeKey = "menu10";
    }
    return activeKey;
}
