export const SAVE_BRANCH = "SAVE_BRANCH";

export function saveBranch(data) {
    return {
        type: SAVE_BRANCH,
        payload: {
            data: data,
        },
    };
}
