import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, notification, Input, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";
import CustomTable from "@components/Table/CustomTable";

import { useHistory } from "react-router-dom";

import { getFilterString } from "@utils";
import { getListAccount, deleteAccount } from "./apiAccount";
import "./index.scss";

let Search = Input.Search;

function Account() {
    let history = useHistory();

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xóa tài khoản này?",
            async onOk() {
                let res = await deleteAccount(record.id);
                if (res.status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Xóa thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;

        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        filterData.active = 1;
        let filter = getFilterString(filterData);
        const res = await getListAccount({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _filter: filter,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            setData(res.data);
            setPagination(newpagination);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push("/account-detail/" + record.id);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xóa
                        </>
                    ),
                },
            ],
        });
    }, [pagination]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        fetch();
        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/account-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 10 }}>
                    <Search
                        placeholder="Tìm kiếm tài khoản"
                        style={{
                            width: 250,
                            marginBottom: 0,
                        }}
                        onSearch={(value) => handleSearch(value)}
                    />
                </Col>
                <Col>
                    <Button
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            localStorage.removeItem("account-table");
                            window.location.reload();
                        }}
                    >
                        Reset tuỳ chỉnh bảng
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push("/add-account");
                        }}
                    >
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <CustomTable
                    tableName="account-table"
                    bordered
                    onRow={onRowTable}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                        x: 1100,
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default Account;
