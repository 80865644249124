import React from "react";
import moment from "moment";
import { numberWithCommas } from "@utils";

export const createColumnConfig = (props) => {
    const { pagination, listStatus } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 90,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Chi nhánh",
            dataIndex: "branch_name",
            width: 230,
            align: "center",
            render: (text, record) => <React.Fragment>{record.branch ? record.branch.data.name : ""}</React.Fragment>,
        },
        {
            title: "Tên khóa học",
            dataIndex: "course_name",
            width: 200,
            align: "center",
            render: (text, record) => <React.Fragment>{record.course ? record.course.data.name : ""}</React.Fragment>,
        },
        {
            title: "Học phí",
            dataIndex: "tuition",
            // width: 10,
            align: "center",
            render: (text) => <React.Fragment>{text && numberWithCommas(text)}</React.Fragment>,
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            // width: 10,
            align: "center",
            filters: [
                {
                    text: "Đang học",
                    value: "1",
                },
                {
                    text: "Kết thúc",
                    value: "2",
                },
                {
                    text: "Hủy bỏ",
                    value: "3",
                },
                {
                    text: "Bảo lưu",
                    value: "4",
                },
            ],

            defaultFilteredValue: ["1"],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            render: (text, record) => (
                <>
                    {text != undefined && text != null
                        ? listStatus &&
                          listStatus.map((item, index) => {
                              return <span key={index}>{text == item.id && item.name}</span>;
                          })
                        : ""}
                </>
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: 180,
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            // width: 25,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    return defaultColumn;
};
