import React from "react";
import moment from "moment";

export const createColumnConfig = (props) => {
    const { pagination, listTeacher, listBranch, branchRedux } = props;
    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: "8%",
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.per_page + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Tên sân",
            dataIndex: "name",
            width: "20%",
            align: "center",
        },
        // {
        //     title: "Lịch học",
        //     dataIndex: "gender",
        //     width: "15%",
        //     align: "center",
        //     render: (text, record, index) => {
        //         return <div>Hello</div>;
        //     },
        // },
        {
            title: "Chi nhánh",
            dataIndex: "branch_id",
            width: "15%",
            align: "center",
            render: (text) => {
                let branch = listBranch && listBranch.filter((item) => item.id == text);
                return <div>{branch[0]?.name}</div>;
            },

            filters: !branchRedux
                ? listBranch.map((item) => {
                      return { value: item.id, text: item.name };
                  })
                : null,
            filterMultiple: false,
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: "15%",
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            width: "15%",
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    return defaultColumn;
};
