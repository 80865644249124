import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Table, Modal, notification } from "antd";
import { DeleteOutlined, EditOutlined, CalendarOutlined, SaveOutlined } from "@ant-design/icons";

import { useHistory, useParams } from "react-router-dom";
import ContextMenu from "@components/ContextMenu";
import useStateCallback from "@utils/CallbackHook";
import { useSelector } from "react-redux";

import { getFilterString } from "@utils";

import "./index.scss";
import { createColumnConfig } from "./ColumnsCourse";
import { heightDesktopConfig, getHeightOfMainContent } from "@utils";
import { getListCourse, cancelStudentCourse, reverseStudentCourse } from "./apiStudent";

let listStatus = [
    { id: "1", name: "Đang học" },
    { id: "2", name: "Kết thúc" },
    { id: "3", name: "Hủy bỏ" },
    { id: "4", name: "Bảo lưu" },
];

function StudentCourseComponent() {
    let { student_id } = useParams();
    let history = useHistory();
    const branch_id = useSelector((state) => state.branch.branch_id);
    const firstRenderInitial = useRef(true);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const [nameStudent, setNameStudent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onShowCancelRow = (record) => {
        Modal.confirm({
            title: "Xác nhận hủy khóa học",
            async onOk() {
                let res = await cancelStudentCourse(record.id);
                if (res.status) {
                    let newFilterData = { ...filterData };
                    newFilterData.status = ["1", "4"];
                    setFilterData({ ...newFilterData });
                    fetch(1, "id:desc", { ...newFilterData });
                    notification.success({
                        message: "Thông báo",
                        description: "Xóa thành hủy thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onChangeReserve = (record) => {
        Modal.confirm({
            title: "Bạn có muốn bảo lưu khóa học?",
            async onOk() {
                let res = await reverseStudentCourse(record.id);
                if (res.status) {
                    let newFilterData = { ...filterData };
                    newFilterData.status = ["1", "4"];
                    setFilterData({ ...newFilterData });
                    fetch(1, "id:desc", { ...newFilterData });
                    notification.success({
                        message: "Thông báo",
                        description: "Bảo lưu thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let sortQuery = "";
        let pager = { ...pagination };
        let newFilterData = { ...filterData };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            sortQuery = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
            fetch(pagination.current, sortQuery);
        } else {
            if (Object.keys(filters).length !== 0) {
                if (filters.status && filters.status.length > 0) {
                    newFilterData.status = filters.status;
                } else {
                    delete newFilterData.status;
                }
            }
            setFilterData(newFilterData);
            fetch(pagination.current, sortQuery, newFilterData);
        }
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        const res = await getListCourse({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _relations: "course,branch,student",
            _filter: `student_id:${student_id};${filter}`,
            student_id,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            setData(res.data);
            setPagination(newpagination);
            setLoading(false);
            setNameStudent(res.name_student);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listStatus,
        });
        setColumns(columnConfig);
    }, [pagination]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        if (branch_id || branch_id == "") {
            let newFilterData = { ...filterData };
            newFilterData.branch_id = branch_id;
            newFilterData.status = ["1", "4"];
            setFilterData({ ...newFilterData });
            fetch(1, "id:desc", { ...newFilterData });
        } else {
            let newFilterData = { ...filterData };
            newFilterData.status = ["1", "4"];
            setFilterData({ ...newFilterData });
            fetch(1, "id:desc", { ...newFilterData });
        }
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) =>
                        history.push("/student-lesson/" + student_id + "/" + record.course_id + "/" + record.id),
                    render: () => (
                        <>
                            <CalendarOutlined style={{ fontSize: 16, padding: 5 }} />
                            Buổi học
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => {
                        history.push({
                            pathname: "/update-student-course/" + student_id + "/" + record.course_id + "/" + record.id,
                        });
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: (record) => record.status == "1",
                    onClick: (record) => onChangeReserve(record),
                    render: () => (
                        <>
                            <SaveOutlined style={{ fontSize: 16, padding: 5 }} />
                            Bảo lưu
                        </>
                    ),
                },
                {
                    showItem: (record) => record.status != "3",
                    onClick: (record) => onShowCancelRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Hủy khóa
                        </>
                    ),
                },
            ],
        });
    }, []);

    useEffect(() => {
        if (firstRenderInitial.current) {
            firstRenderInitial.current = false;
            return;
        }
        let newFilterData = { ...filterData };
        if (branch_id || branch_id == "") {
            newFilterData.branch_id = branch_id;
            setFilterData({ ...newFilterData });
            fetch(1, "id:desc", { ...newFilterData });
        }
    }, [branch_id]);

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 10 }}>
                    <span>Học viên:</span>
                    <span className="name-student">{nameStudent}</span>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push("/add-student-course/" + student_id);
                        }}
                    >
                        Đăng ký khóa học
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <Table
                    bordered
                    onRow={onRowTable}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                        x: 1100,
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default StudentCourseComponent;
