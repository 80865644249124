import api from "@api";

export const api_getListStudentMoney = (param) => {
    const url = `statistic/remind_students_to_pay`;
    return api.get(url, {
        params: {
            branch_id: param?.branch_id,
        },
    });
};

export const api_getAllInvoices = (param) => {
    const url = "/invoice";
    return api.get(url, {
        params: {
            _paging: param?.paging,
        },
    });
};

export const api_getAllCourses = (param) => {
    const url = `/course`;
    return api.get(url, {
        params: {
            _page: param.page,
            _filter: param.filters,
            _paging: param?.paging,
        },
    });
};

export const api_getAllStudent = async (param) => {
    const url = "/student";
    return api.get(url, {
        params: {
            _page: param?.page,
            _filter: param?.filters,
            _paging: param?.paging,
        },
    });
};

export const api_getAllTeacher = (params) => {
    const url = `/teacher`;
    return api.get(url, {
        params: {
            _page: params?.page,
            _filter: params?.filters,
            _paging: params?.paging,
        },
    });
};

export const api_getAllBranch = (params) => {
    const url = `/branch`;
    return api
        .get(url, {
            params: { ...params, _orderBy: "id:asc", _filter: "active:1" },
        })
        .catch((err) => err);
};

export const api_getAllBranchOptions = (params) => {
    const url = `/branch/options`;
    return api
        .get(url, {
            params: { ...params, _orderBy: "id:asc", _filter: "active:1" },
        })
        .catch((err) => err);
};

export const api_getAllCourse = (params) => {
    const url = `/course`;
    return api.get(url, { params }).catch((err) => err);
};

export const api_uploadImage = (file) => {
    const url = `/upload`;
    var bodyFormData = new FormData();
    if (file) {
        bodyFormData.append("file", file.originFileObj);
        api.defaults.headers.common["Content-Type"] = "multipart/form-data";
        return api.post(url, bodyFormData);
    } else {
        return null;
    }
};

export const api_deleteImage = (file) => {
    const url = `/delete_file`;
    return api.post(url, { filename: file });
};
