import React from "react";
import { Image } from "antd";
import moment from "moment";

export const createColumnConfig = (props) => {
    const { pagination } = props;
    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 90,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.per_page + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Ảnh giáo viên",
            dataIndex: "image",
            width: 130,
            align: "center",
            render: (text, record, index) => {
                return text ? <Image src={text} style={{ width: 75, height: "auto" }} /> : <div>Chưa có</div>;
            },
        },
        {
            title: "Họ tên",
            dataIndex: "name",
            width: 180,
            align: "center",
        },
        {
            title: "Giới tính",
            dataIndex: "gender",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return <div>{text == 0 ? "Nữ" : "Nam"}</div>;
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 200,
            align: "center",
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
            width: 120,
            align: "center",
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            width: 250,
            align: "center",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return <div>{text == "1" ? "Đang dạy" : "Đã nghỉ"}</div>;
            },
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: 250,
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            width: 180,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at
                            ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
                            : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    return defaultColumn;
};
