import React, { useEffect, useRef } from "react";
import { Form, Spin } from "antd";
import "./index.scss";
import { closest } from "./helpers";
import FormInstance from "./FormInstance";

const useForm = () => {
    const [form] = Form.useForm();
    const instance = new FormInstance(form);

    return [instance];
};

const NewForm = (props) => {
    let { children, className, onFinish, form, visible = true } = props;
    if (!(form instanceof FormInstance)) {
        throw "[NewForm] prop 'form' must be instance of NewForm/FormInstance";
    }
    let newFormProps = { className, onFinish, form: form.form };
    const formContent = useRef();
    form.setRef(formContent);

    useEffect(() => {
        formContent.current.__formInstance = form;
    });

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (formContent.current) {
                    formContent.current.style.opacity = "1";
                }
            }, 300);
        }
    }, [visible]);

    return (
        <Form {...newFormProps}>
            <div className="new-form-content form-instance-element" ref={formContent}>
                {visible ? (
                    <div>{children}</div>
                ) : (
                    <div>
                        <div style={{ paddingTop: 100, textAlign: "center" }}>
                            <Spin />
                        </div>
                    </div>
                )}
            </div>
        </Form>
    );
};

NewForm.useForm = useForm;

const FieldFloatLabel = (props) => {
    let { label, children } = props;
    return (
        <div className="form-group-label">
            <span style={{ display: "block", marginBottom: 5 }}>
                <label>{label}</label>
            </span>
            {children}
        </div>
    );
};

const FieldFloatItem = (props) => {
    const { name, label, rules, children } = props;
    return (
        <Item name={name} label={label} rules={rules}>
            {children}
        </Item>
    );
};

const Item = (props) => {
    const { children, label, name, rules } = props;
    const ref = useRef();

    const required = rules?.find((r) => r.required) ? <span style={{ marginLeft: 5, color: "red" }}>*</span> : null;

    useEffect(() => {
        setTimeout(() => {
            if (name) {
                if (ref.current) {
                    ref.current.__formItemName = name;
                    let formEl = closest(ref.current, ".form-instance-element");
                    formEl.__formInstance.initEvent(ref.current);
                }
            }
        }, 300);
    }, []);

    return (
        <div className="float-label" ref={ref}>
            <Form.Item name={name} rules={rules}>
                {children}
            </Form.Item>
            <label className="label">
                {label}
                {required}
            </label>
        </div>
    );
};

export default NewForm;

export { FieldFloatLabel, FieldFloatItem };
