import React from "react";
import { numberWithCommas } from "@utils";

export const createColumnConfig = (props) => {
    const { pagination } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 90,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Tên chi nhánh",
            dataIndex: "name",
            // width: "20%",
            align: "center",
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            // width: "15%",
            align: "center",
        },
        {
            title: "Số sân thuê",
            dataIndex: "number_yard",
            // width: "20%",
            align: "center",
        },
        {
            title: "Tiên thuê sân",
            dataIndex: "yard_rent",
            // width: "20%",
            align: "center",
            render: (text, record) => <span>{numberWithCommas(text)}</span>,
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            width: 100,
            align: "center",
            render: (text, record) => <span>{record.active == 1 ? "Đang mở" : "Đã đóng"}</span>,
        },
    ];

    return defaultColumn;
};
