import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, matchPath } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, notification } from "antd";
import { Link } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getRole, getUserInfo } from "@utils/auth";
import { getAllMenu } from "@actions/menu";

import { focusSubMenu } from "@utils/menu";

let SubMenu = Menu.SubMenu;

let { Sider } = Layout;

let defaultProps = {
    items: [],
};

let propTypes = {
    items: PropTypes.array,
};

function Sidebar(props) {
    let { history, collapse, setCollapsed } = props;
    const [openKey, setOpenKey] = useState("");
    const [activeKey, setActiveKey] = useState("");
    const [menu, setMenu] = useState([]);
    const acl_token = useSelector((state) => state.acl.acl_token);
    const acl = useSelector((state) => state.acl.acl);
    const items = useSelector((state) => state.menu.items);
    const dispatch = useDispatch();
    let isActive = (path, history) => {
        return matchPath(path, {
            path: history.location.pathname,
            exact: true,
            strict: false,
        });
    };

    const handleActivationSubmenu = (pathname) => {
        let activeKey = focusSubMenu(pathname);
        if (activeKey != null) {
            setActiveKey(activeKey);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
        dispatch(getAllMenu());
    }, []);

    useEffect(() => {
        let has_active_main_menu = false;
        Array.isArray(items) &&
            items.map((item) => {
                Array.isArray(item.child) &&
                    item.child.map((node) => {
                        if (node.url && isActive(node.url, history)) {
                            has_active_main_menu = true;
                            menuClickHandle({
                                key: "menu" + node.key,
                                keyPath: ["menu" + node.key, "sub" + item.key],
                            });
                        }
                    });
            });
        if (!has_active_main_menu) {
            handleActivationSubmenu(history.location.pathname);
        }
    }, [props, isActive]);

    const validateRole = (menus, roles) => {
        for (let i = 0; i < menus.length; i++) {
            let exists = false;
            if (menus[i].child) {
                menus[i].child = validateRole(menus[i].child, roles);
                if (menus[i].child.length !== 0) {
                    exists = true;
                }
            } else {
                if (acl.hasAllowed(roles, menus[i].resource, "view_menu")) {
                    exists = true;
                }
            }
            if (!exists) {
                menus.splice(i, 1);
                i--;
            }
        }
        return menus;
    };

    const renderMenu = () => {
        let _menuProcess = (nodes, pkey) => {
            return (
                Array.isArray(nodes) &&
                nodes.map((item) => {
                    let menu = _menuProcess(item.child, item.key);
                    if (item.url && isActive(item.url, history)) {
                        setActiveKey("menu" + item.key);
                        setOpenKey("sub" + pkey);
                        // activeKey = "menu" + item.key;
                        // openKey = "sub" + pkey;
                    }
                    if (menu && menu.length > 0) {
                        return (
                            <SubMenu
                                key={"sub" + item.key}
                                title={
                                    <span>
                                        {item.icon}
                                        <span className="nav-text">{item.name}</span>
                                    </span>
                                }
                            >
                                {menu}
                            </SubMenu>
                        );
                    } else {
                        return item.url ? (
                            <Menu.Item key={"menu" + item.key}>
                                <Link to={item.url}>
                                    {item.icon}
                                    <span>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        ) : (
                            <Menu.Item key={"menu" + item.key}>
                                {item.icon}
                                <span>{item.name}</span>
                            </Menu.Item>
                        );
                    }
                })
            );
        };

        let menuItems = validateRole(
            items.filter((item) => item.general_menu || (!item.general_menu && !getUserInfo().is_admin)),
            getRole(acl_token)
        );

        setMenu(_menuProcess(menuItems));
    };

    useEffect(() => {
        renderMenu();
    }, [items, acl_token]);

    const toggle = () => {
        setCollapsed(!collapse);
    };

    return (
        <Sider trigger={null} collapsible collapsed={collapse}>
            <div className="logo">
                {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: "trigger",
                    onClick: toggle,
                })}
                <div className="ant-layout-logo">{!collapse ? "TTBĐ Ngọc Hùng" : ""}</div>
            </div>
            <Menu
                selectedKeys={[`${activeKey}`]}
                // openKeys={openKey2}
                defaultOpenKeys={[`${openKey}`]}
                // onOpenChange={() => {
                //     setopenKey2(['sub6'])
                // }}
                theme="dark"
                mode="inline"
                siderCollapsed={collapse}
            >
                {menu}
            </Menu>
        </Sider>
    );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default withRouter(Sidebar);
