import AclProcessor from "@utils/AclProcessor";

const initialState = {
    acl: null,
};

export default function acl(state = initialState, action = {}) {
    switch (action.type) {
        case "CREATE_ACL":
            return {
                ...state,
                acl: new AclProcessor().getAcl(),
            };
        case "SAVE_ACL":
            return {
                ...state,
                acl_token: action.payload,
            };
        case "REMOVE_ACL":
            return { acl: null };
        default:
            return state;
    }
}
