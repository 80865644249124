import React from "react";
import moment from "moment";

export const createColumnConfig = (props) => {
    const { pagination, listStatus } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 90,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Tên buổi học",
            dataIndex: "name",
            // width: 15,
            align: "center",
        },
        {
            title: "Ngày học",
            dataIndex: "learn_date",
            // width: 10,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.learn_date ? moment(record.learn_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            // width: 10,
            align: "center",
            render: (text, record) => (
                <>
                    {text != undefined && text != null
                        ? listStatus &&
                          listStatus.map((item, index) => {
                              return <span key={index}>{text == item.id && item.name}</span>;
                          })
                        : ""}
                </>
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            // width: 15,
            align: "center",
            render: (text, record) => (
                <>
                    {record.note ? (
                        <React.Fragment>
                            <div>Lý do: {text}</div>
                            <div>
                                Ngày học lại:{" "}
                                {record.date_make_up_lesson
                                    ? moment(record.date_make_up_lesson, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
                                    : ""}
                            </div>
                        </React.Fragment>
                    ) : (
                        record.date_make_up_lesson && (
                            <div>
                                Ngày học lại:{" "}
                                {record.date_make_up_lesson
                                    ? moment(record.date_make_up_lesson, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
                                    : ""}
                            </div>
                        )
                    )}
                </>
            ),
        },
    ];

    return defaultColumn;
};
