import React, { useState, useEffect, useRef } from "react";
import { Input, Select, Row, Col, Button, notification, Form } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "../../components/CenterArea";
import { getInvoice, createInvoice, updateInvoice, getListInvoices } from "./apiInvoice";
import { api_getAllBranch, api_getAllBranchOptions } from "@api/globalApi";
import { numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { getRole, getUserInfo } from "@utils/auth";

let FormItem = Form.Item;
let Option = Select.Option;
let { TextArea } = Input;
const user_info = getUserInfo();

function InvoiceDetail(props) {
    // get props
    const { pathname } = props.location;
    const { params } = props.match;
    //define form instance
    const [form] = NewForm.useForm();

    //define state
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    //mounted
    const mounted = useRef(false);

    let paramsForCallingApiBranch = {
        params: {
            _relations: "creator,confirm_by,course,branch,deletor",
        },
    };
    //define functions
    const getInvoiceData = async () => {
        let { invoice_id } = params;
        const { status, data } = await getInvoice(invoice_id, paramsForCallingApiBranch);
        if (status) return data;
    };

    const getListBranchData = async () => {
        let params = { params: { _orderBy: "id:asc" } };
        const { status, data } = await api_getAllBranchOptions(params);
        if (status) return toArray(data);
    };

    useEffect(() => {
        mounted.current = true;
        setLoading(true);

        const promiseArr = [];
        promiseArr.push(getListBranchData());
        if (pathname !== "/create-invoice") {
            promiseArr.push(getInvoiceData());
        }

        Promise.all(promiseArr).then(
            (values) => {
                let data = pathname === "/create-invoice" ? null : values[1];
                setBranches(values[0]);
                form.setFieldsValue({
                    branch_id: data?.branch?.data?.id || user_info.branch_id,
                    course_id: data?.course_id || "",
                    code: data?.code || "",
                    name: data?.name || "",
                    type: data?.type?.toString() || "1",
                    content: data?.content || "",
                    amount: numberWithCommas(data?.amount) || 0,
                    status: data?.status || 1,
                    creator: data?.creator?.data?.name || user_info.name,
                    creator_id: data?.creator?.data?.id || user_info.id,
                    birthday: data?.birthday || "",
                    phone: data?.phone || "",
                    note: data?.note || "",
                    email: data?.email || "",
                });
                setLoading(false);
            },
            () => setLoading(false)
        );
        return () => {
            mounted.current = false;
            if (!props.history.location.pathname.includes("/invoice")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const handleSubmit = () => {
        setLoadingBtn(true);
        form.validateFields().then(
            async (values) => {
                let dataInvoice = { ...values };
                dataInvoice.amount = numberWithoutCommas(values.amount);
                dataInvoice.creator = values.creator_id;
                dataInvoice.type = +dataInvoice.type;
                if (params.invoice_id) {
                    let res = await updateInvoice(params.invoice_id, dataInvoice);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoadingBtn(false);
                } else {
                    let res = await createInvoice(dataInvoice);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        let res = await getListInvoices(paramsForCallingApiBranch);
                        if (res.status) {
                            let firstItemId = res.data[0].id;
                            props.history.replace({
                                pathname: "/invoice-detail/" + firstItemId,
                            });
                        }
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoadingBtn(false);
                }
            },
            () => setLoadingBtn(false)
        );
    };

    const handleChangeMoney = (e) => {
        let { value } = e.target;
        form.setFieldsValue({
            amount: value ? numberWithCommas(value) : 0,
        });
    };

    const handleBack = () => {
        props.history.push({
            pathname: `/invoice`,
        });
    };

    const checkDisabledField = () => {
        if (user_info.branch_id) {
            return true;
        } else {
            if (pathname.includes("create") || pathname.includes("add")) {
                return false;
            }
            return true;
        }
    };

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm className="new-form" form={form} onFinish={handleSubmit}>
                {!loading && (
                    <>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Chi nhánh" name="branch_id">
                                    <Select disabled={checkDisabledField()}>
                                        {branches.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Người tạo" name="creator">
                                    <Input disabled={true} />
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Người xác nhận" name="confirm_by">
                                    <Select disabled={true}>
                                        <Option value={1}>Admin</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        {pathname !== "/create-invoice" && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col span={24}>
                                    <FieldFloatItem label="Mã phiếu" name="code">
                                        <Input disabled />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )}

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Loại" name="type">
                                    <Select>
                                        <Option value={"0"}>Chi</Option>
                                        <Option value={"1"}>Thu</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Họ tên"
                                    name="name"
                                    rules={[{ required: true, message: "Họ tên không được bỏ trống" }]}
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Số điện thoại"
                                    name="phone"
                                    rules={[{ required: true, message: "Số điện thoại không được bỏ trống" }]}
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Email" name="email">
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem label="Ngày sinh" name="birthday">
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Nội dung" name="content">
                                    <TextArea row={2} />
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Trạng thái" name="status">
                                    <Select>
                                        <Option value={1}>Chưa thanh toán</Option>
                                        <Option value={2}>Đã thanh toán</Option>
                                        <Option value={3}>Đã huỷ</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col span={24}>
                                <FieldFloatItem label="Số tiền" name="amount">
                                    <Input
                                        style={{ textAlign: "right" }}
                                        maxLength={13}
                                        addonAfter={"VNĐ"}
                                        onChange={handleChangeMoney}
                                    />
                                </FieldFloatItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <FormItem>
                                    <Row style={{ marginBottom: 100 }}>
                                        <Col style={{ marginRight: 10 }}>
                                            <Button type="default" onClick={handleBack}>
                                                Quay lại
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button type="primary" loading={loadingBtn} htmlType="submit">
                                                Lưu
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                )}
            </NewForm>
        </CenterArea>
    );
}

export default InvoiceDetail;
