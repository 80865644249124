class FormEventListener {
    constructor(form) {
        this.form = form;
    }
    setRef(ref) {
        this.ref = ref;
    }
    elements() {
        if (this.ref && this.ref.current && this.ref.current.querySelectorAll) {
            return [...this.ref.current.querySelectorAll(".float-label")];
        }
        return [];
    }
    triggerNewValues(values) {
        let elements = this.elements();
        for (let name of values) {
            let element = elements.find((el) => el.__formItemName == name);
            if (element) {
                let value = this.form.getFieldValue(name);
                if (value) {
                    this.activateLabel(element);
                } else {
                    this.deactivateLabel(element);
                }
            }
        }
    }
    activateLabel(element) {
        element && element.querySelector && element.querySelector(".label").classList.add("label-float");
    }
    deactivateLabel(element) {
        element && element.querySelector && element.querySelector(".label").classList.remove("label-float");
    }
    initEvent(element) {
        if (element.__inited_formEvents) {
            // return
        }
        element.__inited_formEvents = true;
        let antSelect = element.querySelector(".ant-select");
        if (antSelect) {
            this.addAntSelectEvent(antSelect, element, this.form);
            return;
        }
        let antPicker = element.querySelector(".ant-picker");
        if (antPicker) {
            this.addAntPickerEvent(antPicker, element, this.form);
            return;
        }
        let textarea = element.querySelector("textarea");
        if (textarea) {
            this.addTextareaEvent(textarea, element, this.form);
            return;
        }
        let input = element.querySelector("input");
        if (input) {
            this.addInputEvent(input, element, this.form);
        }
    }
    initEvents() {
        this.elements().forEach((element) => {
            this.initEvent(element);
        });
    }
    addInputEvent(input, element, form) {
        let name = element.__formItemName;
        if (input) {
            if (form.getFieldValue(name)) {
                this.activateLabel(element);
            }
            input.addEventListener("focus", () => {
                this.activateLabel(element);
            });
            input.addEventListener("blur", () => {
                !form.getFieldValue(name) && this.deactivateLabel(element);
            });
        }
    }
    addTextareaEvent(textarea, element, form) {
        this.addInputEvent(textarea, element, form);
    }
    addAntPickerEvent(antPicker, element, form) {
        let name = element.__formItemName;
        if (antPicker) {
            let input = antPicker.querySelector("input");
            let focused = false;
            if (input) {
                if (form.getFieldValue(name)) {
                    this.activateLabel(element);
                } else {
                    this.deactivateLabel(element);
                }
                input.addEventListener("focus", () => {
                    this.activateLabel(element);
                    focused = true;
                });
                input.addEventListener("blur", () => {
                    !form.getFieldValue(name) && this.deactivateLabel(element);
                    focused = false;
                });
            }
            antPicker.addEventListener("mouseup", () => {
                setTimeout(() => {
                    if (!focused) {
                        if (!form.getFieldValue(name)) {
                            this.deactivateLabel(element);
                        }
                    }
                });
            });
        }
    }
    addAntSelectEvent(antSelect, element, form) {
        let name = element.__formItemName;
        if (antSelect) {
            let value = form.getFieldValue(name);
            if (value instanceof Array) {
                if (value.length > 0) {
                    this.activateLabel(element);
                } else {
                    this.deactivateLabel(element);
                }
            } else {
                if (value) {
                    this.activateLabel(element);
                } else {
                    this.deactivateLabel(element);
                }
            }
            let input = antSelect.querySelector("input");
            let focused = false;
            if (input) {
                input.addEventListener("focus", () => {
                    this.activateLabel(element);
                    focused = true;
                });
                input.addEventListener("blur", () => {
                    let value = form.getFieldValue(name);
                    if (!value || (value instanceof Array && value.length === 0)) {
                        this.deactivateLabel(element);
                    }
                    focused = false;
                });
            }
            antSelect.addEventListener("mouseup", () => {
                console.log("click select");
                setTimeout(() => {
                    if (!focused) {
                        let value = form.getFieldValue(name);
                        if (!value || (value instanceof Array && value.length === 0)) {
                            this.deactivateLabel(element);
                        }
                    }
                });
            });
        }
    }
}

export default FormEventListener;
