import React, { useState, useEffect, useRef } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table, Input, notification, Modal, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { getListInvoices, removeInvoice } from "./apiInvoice";
import { useSelector } from "react-redux";
import TableKey from "@utils/TableKey";
import Media, { useMedia } from "react-media";
import { getLocalColumns, heightDesktopConfig, numberWithCommas, getHeightOfMainContent } from "@utils";
import IndexForMobile from "./IndexForMobile";
import useStateCallback from "@utils/CallbackHook";
import "./index.scss";
import ContextMenu from "@components/ContextMenu";
import { getFilterString, toArray } from "@utils";
import { getRole, getUserInfo } from "@utils/auth";
import { api_getAllBranch, api_getAllBranchOptions } from "@api/globalApi";

let Search = Input.Search;
let fixColumns = ["code", "type", "fullname", "content", "status", "collector", "confirm", "paid", "phone"];

function Invoice(props) {
    const isLargeScreen = useMedia({ query: "(min-width: 768px)" });
    const acl = useSelector((state) => state.acl.acl);
    let localColumns = getLocalColumns(TableKey.invoice, fixColumns);
    const branch_id = useSelector((state) => state.branch.branch_id);
    const user_info = getUserInfo();

    //Table
    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        showSizeChanger: false,
    });
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [configColumns, setConfigColumns] = useState(localColumns);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");
    const [branches, setBranches] = useState([]);
    const mounted = useRef(false);
    // Mobile
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    let newColumns = [
        {
            title: "STT",
            fixed: isLargeScreen ? "left" : "center",
            width: 40,
            align: "center",
            key: isLargeScreen ? "__" : "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Mã phiếu",
            dataIndex: "code",
            align: "center",
            width: 100,
            key: "code",
        },
        {
            title: "Loại",
            dataIndex: "type",
            width: 60,
            render: (text, record) => <span>{record.type == 1 ? "Thu" : "Chi"}</span>,
            key: "type",
            align: "center",
        },
        {
            title: "Họ tên",
            dataIndex: "name",
            width: 150,
            key: "fullname",
            align: "center",
        },
        {
            title: "Chi nhánh",
            dataIndex: "branch_id",
            width: 150,
            key: "branch_id",
            render: (text, record) => {
                let branch = branches && branches.filter((item) => item.id == text);
                return <span>{branch[0]?.name}</span>;
            },
            align: "center",
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
            width: 100,
            key: "phone",
            align: "center",
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            width: 200,
            key: "content",
            align: "center",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (text, record) => (
                <span>
                    {record.status
                        ? record.status == 1
                            ? "Chưa thanh toán"
                            : record.status == 2
                            ? "Đã thanh toán"
                            : record.status == 3
                            ? "Đã huỷ"
                            : ""
                        : ""}
                </span>
            ),
            filters: [
                { text: "Chưa thanh toán", value: 1 },
                { text: "Đã thanh toán", value: 2 },
                { text: "Đã huỷ", value: 3 },
            ],
            filterMultiple: false,
            width: 100,
            key: "status",
            align: "center",
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            align: "center",
            // className: "column-money",
            render: (text, record) => <span>{`${numberWithCommas(record.amount)} VNĐ`}</span>,
            width: 120,
            key: "paid",
        },
    ];

    let actionColumns = [
        ...newColumns,
        {
            title: "Hành động",
            dataIndex: "",
            width: 130,
            align: "center",
            key: "action",
            render: (text, record, index) => (
                <span style={{ display: "inline-block" }}>
                    <Link
                        className="pne-update-controll"
                        to={{
                            pathname: "/invoice-detail/" + record.id,
                            state: { invoiceData: record },
                        }}
                    >
                        <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                    </Link>
                    <Popconfirm
                        title="Chắc chắn xoá?"
                        onConfirm={() => onDeleteRow(record, index)}
                        okText="Đồng ý"
                        cancelText="Không"
                    >
                        <a title="Huỷ bỏ">
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                        </a>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    // useEffect(() => {
    //     let user_info = getUserInfo()
    //     if (data) {
    //         for (let i of data) {
    //             console.log(i.branch_id, i.branch_id === user_info.branch_id)
    //             if (i.branch_id !== user_info.branch_id) {
    //                 actionColumns.pop()
    //             }
    //         }
    //     }
    // }, [data])

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const resetConfigColumns = () => {
        setConfigColumns(fixColumns);
        localStorage.setItem(TableKey.invoice, JSON.stringify(fixColumns));
    };

    const handleTableChange = (_pagination, filters) => {
        let sortQuery = "";
        let newFilterData = { ...filterData };

        if (Object.keys(filters).length !== 0) {
            if (filters.status && filters.status.length > 0) {
                newFilterData.status = filters.status[0].toString();
            } else {
                delete newFilterData.status;
            }
        }
        setFilterData(newFilterData);
        fetch(_pagination.current, sortQuery, newFilterData);
    };

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const showHistoryRemove = () => {
        props.history.push("/cancel-invoice");
    };

    const createNewInvoice = () => {
        props.history.push("/create-invoice");
    };

    const onUpdateRow = (record) => {
        sessionStorage.setItem("pagination", pagination.current);
        props.history.push({
            pathname: "/invoice-detail/" + record.id,
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const onDeleteRow = (record) => {
        // if (isLargeScreen) {
        Modal.confirm({
            title: "Bạn có chắc muốn xoá không ?",
            async onOk() {
                // api.put("/invoice/cancel/" + record.id).then(function (res) {
                //     if (res.status == true) {
                //         fetch();
                //         notification.success({
                //             message: "Thông báo",
                //             description: "Huỷ thành công",
                //         });
                //     } else {
                //         notification.error({
                //             message: "Thông báo",
                //             description: res.message,
                //         });
                //     }
                // }
                let { status } = await removeInvoice(record.id);
                if (status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Xóa thành công",
                    });
                } else {
                    notification.error({
                        message: "Thông báo",
                        description: res.message,
                    });
                }
            },
        });
        // } else {
        //     api.put("/invoice/cancel/" + record.id).then(function (res) {
        //         if (res.status == true) {
        //             fetch();
        //             notification.success({
        //                 message: "Thông báo",
        //                 description: "Huỷ thành công",
        //             });
        //         } else {
        //             notification.error({
        //                 message: "Thông báo",
        //                 description: res.message,
        //             });
        //         }
        //     });
        // }
    };

    useEffect(async () => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });

        let res = await getListBranchData();
        setBranches(res);
    }, []);

    useEffect(() => {
        return () => {
            if (!props.history.location.pathname.includes("/invoice-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    useEffect(() => {
        if (data.length !== 0 && isLargeScreen) {
            setColumns(newColumns);
        } else {
            setColumns(actionColumns);
        }
    }, [data, pagination, branches]);

    const fetch = async (page = 1, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        let params = {
            params: {
                _relations: "creator,confirm_by,course,branch,deletor",
                _page: page,
                _orderBy: sort,
                _filter: filter,
            },
        };
        let res = await getListInvoices(params);
        setLoading(false);
        if (res.status) {
            setData(res.data);
            setPagination({
                ...pagination,
                total: res.meta.pagination.total,
                pageSize: res.meta.pagination.per_page,
                current: res.meta.pagination.current_page,
            });
        }
    };

    const getListBranchData = async () => {
        let params = { params: { _orderBy: "id:asc" } };
        const { status, data } = await api_getAllBranchOptions(params);
        if (status) return toArray(data);
    };

    useEffect(async () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branch_id || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    }, [branch_id]);

    const checkRoleBranch = (record, type) => {
        if (user_info.branch_id) {
            //is_staff
            if (acl.hasAllowed(getRole(), "invoice", type) && user_info.branch_id === record.branch_id) {
                return true;
            }
            return false;
        } else {
            //is_admin
            return true;
        }
    };

    useEffect(() => {
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: (record) => checkRoleBranch(record, "update"),
                    onClick: (record) => onUpdateRow(record),
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: (record) => checkRoleBranch(record, "cancel"),
                    onClick: (record) => onDeleteRow(record),
                    render: () => (
                        <>
                            {/* <a title="Huỷ phiếu"> */}
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xóa
                            {/* </a> */}
                        </>
                    ),
                },
            ],
        });
    }, [pagination, branch_id]);

    // useEffect(async () => {
    //     mounted.current = true;
    //     setLoading(true);
    //     if (branches.length !== 0) {
    //         let paginationStorage = sessionStorage.getItem("pagination");
    //         let { status, data, meta } = await fetch(
    //             paginationStorage ? paginationStorage : pagination.current,
    //             _,
    //             filterData
    //         );
    //     }

    //     return () => (mounted.current = false);
    // }, [pagination.current, filterData, branches]);

    let rowSelection = false;
    // if (acl.hasAllowed(getRole(), "invoice", "confirm")) {
    //     rowSelection = {
    //         selectedRowKeys,
    //         onChange: onSelectChange,
    //     };
    // }

    let hasSelected = selectedRowKeys.length > 0;

    return (
        <div>
            <Media
                query="(min-width: 768px)"
                render={() => (
                    <React.Fragment>
                        <Row type="flex" justify="space-between">
                            <Col>
                                <Search
                                    placeholder="Tìm kiếm theo họ tên hoặc SĐT"
                                    style={{ width: 300, marginBottom: 20 }}
                                    onSearch={handleSearch}
                                />
                            </Col>
                            <Col>
                                <Button type="primary" onClick={createNewInvoice} loading={loadingConfirm}>
                                    Thêm hoá đơn
                                </Button>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center">
                            <Col span={24}>
                                <Row style={{ marginBottom: 0 }}>
                                    <Table
                                        bordered={true}
                                        onRow={onRowTable}
                                        columns={columns}
                                        rowKey={(record) => record.id}
                                        dataSource={data}
                                        pagination={pagination}
                                        loading={loading}
                                        onChange={handleTableChange}
                                        rowSelection={rowSelection}
                                        scroll={{
                                            y: getHeightOfMainContent(),
                                            x: 2000,
                                        }}
                                    />
                                    <ContextMenu {...menuContext} />
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            />

            <Media
                query="(max-width: 767px)"
                render={() => (
                    <IndexForMobile
                        fetch={fetch}
                        showHistoryRemove={showHistoryRemove}
                        handleSearch={handleSearch}
                        createNewInvoice={createNewInvoice}
                        filterSearch={filterSearch}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        selectedRowKeys={selectedRowKeys}
                        setIndeterminate={setIndeterminate}
                        setSelectedRowKeys={setSelectedRowKeys}
                        loadingConfirm={loadingConfirm}
                        pagination={pagination}
                        loading={loading}
                        data={data}
                        columns={columns}
                        props={props}
                        isLargeScreen={isLargeScreen}
                        resetConfigColumns={resetConfigColumns}
                        filterData={filterData}
                        setFilterData={setFilterData}
                    />
                )}
            />
        </div>
    );
}

export default Invoice;
