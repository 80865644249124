import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";
import { getStudentNumbers } from "../apiDashboard";

let max = 10;

const Student = (p) => {
    const d = new Date();
    const { time } = p;
    const branch_id = useSelector((state) => state.branch.branch_id);

    const [loading, setLoading] = useState(true);
    const [optionsStudent, setOptionsStudent] = useState({
        chart: {
            animations: {
                enabled: false,
            },
            id: "basic-bar",
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    console.log(config);
                },
            },
        },
        xaxis: {
            categories: [],
            labels: {
                rotate: -45,
            },
            title: {
                text: "Thời gian",
                style: {
                    colors: [],
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    cssClass: "apexcharts-yaxis-label",
                },
            },
        },
        yaxis: {
            title: {
                text: "Số lượng học viên",
                style: {
                    colors: [],
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    cssClass: "apexcharts-yaxis-label",
                },
            },
        },
        noData: {
            text: "Không có dữ liệu",
        },
        title: {
            text: "Thống kê tổng số học viên",
            align: "center",
            style: {
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Arial, sans-serif",
            },
        },
    });

    const [seriesStudent, setSeriesStudent] = useState([]);

    const convertMax = (values) => {
        let maxValue = Math.max(...values);

        if (maxValue <= 10) {
            return 10;
        } else {
            let n = Math.pow(10, ("" + maxValue).length - 1);
            return Math.ceil(maxValue / n) * n;
        }
    };

    const countSum = (values) => {
        let sum = 0;
        for (let i = 0; i < values.length; i++) {
            sum += values[i];
        }
        return sum;
    };

    const fetchCountStudent = async () => {
        setLoading(true);
        let params = {
            params: {
                year: time ? moment(time._d).format("YYYY") : d.getFullYear(),
                branch_id,
            },
        };

        let res = await getStudentNumbers(params);
        if (res.status) {
            let a = convertMax(res.values);
            max = a;
            setOptionsStudent({
                ...optionsStudent,

                xaxis: {
                    ...optionsStudent.xaxis,
                    categories: res.months.map((item, index) => {
                        return `Tháng ${item}`;
                    }),
                },
                yaxis: {
                    ...optionsStudent.yaxis,
                    max: a,
                },
                title: {
                    ...optionsStudent.title,
                    text: `Thống kê tổng số học viên (${countSum(res.values)})`,
                },
            });

            // phan nay de check giup do lag khi render
            let checkNull = true;
            for (let i = 0; i < res.values.length; i++) {
                if (res.values[i] !== 0) {
                    checkNull = false;
                    break;
                }
            }
            checkNull
                ? setSeriesStudent([{ name: "số học viên", data: [] }])
                : setSeriesStudent([{ name: "số học viên", data: res.values }]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCountStudent();
    }, [time, branch_id]);

    return (
        <div className="student-dashboard-container">
            <Row gutter={16}>
                <Col span={24}>
                    <div>
                        {!loading && (
                            <Chart
                                options={optionsStudent}
                                series={seriesStudent}
                                type="bar"
                                height="400px"
                                width="100%"
                            />
                        )}
                        {/* <Chart options={optionsStudent} series={seriesStudent} type="bar" width="100%" /> */}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Student;
