import React from "react";
import moment from "moment";

let listStatus = [
    { id: "1", name: "Đang học" },
    { id: "2", name: "Kết thúc" },
    { id: "3", name: "Hủy bỏ" },
    { id: "4", name: "Bảo lưu" },
];

export const createColumnConfig = (props) => {
    const { pagination } = props;

    let defaultColumn = [
        {
            title: "STT",
            fixed: "left",
            width: 80,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Họ tên",
            dataIndex: "name",
            // width: "20%",
            align: "center",
        },
        {
            title: "Trạng thái khóa học",
            dataIndex: "statusCourseOfStudent",
            // width: "15%",
            align: "center",
            filters: [
                {
                    text: "Đang học",
                    value: "1",
                },
                {
                    text: "Kết thúc",
                    value: "2",
                },
                {
                    text: "Hủy bỏ",
                    value: "3",
                },
                {
                    text: "Bảo lưu",
                    value: "4",
                },
            ],
            onFilter: (value, record) => record.statusCourseOfStudent.indexOf(value) === 0,
            render: (text, record) => (
                <>
                    {text
                        ? listStatus &&
                          listStatus.map((item, index) => {
                              return <span key={index}>{text == item.id && item.name}</span>;
                          })
                        : ""}
                </>
            ),
        },
        {
            title: "Số ĐT",
            dataIndex: "phone",
            // width: "15%",
            align: "center",
        },
        // {
        //     title: "Khóa học",
        //     dataIndex: "discount",
        //     width: "15%",
        //     align: "center",
        //     render: (text, record) => (
        //         <React.Fragment>
        //             {record.course_names.length > 0
        //                 ? record.course_names.map((item, index) => {
        //                       return (
        //                           <span
        //                               key={index}
        //                               style={{
        //                                   display: "inline",
        //                               }}
        //                           >
        //                               {record.course_names.length > 1 ? `${item}, ` : `${item}`}
        //                           </span>
        //                       );
        //                   })
        //                 : ""}
        //         </React.Fragment>
        //     ),
        // },
        {
            title: "Giới tính",
            dataIndex: "gender",
            // width: "15%",
            align: "center",
            render: (text, record) => <React.Fragment>{(text == 0 && "Nữ") || (text == 1 && "Nam")}</React.Fragment>,
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            width: 200,
            align: "center",
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: 200,
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            // width: "15%",
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at
                            ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
                            : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    return defaultColumn;
};
