export default [
    {
        id: "thu_mon",
        label: "Thủ môn",
        values: [
            {
                id: "GK",
                name: "Thủ môn",
            },
        ],
    },
    {
        id: "hau_ve",
        label: "Hàng hậu vệ",
        values: [
            {
                id: "SW",
                name: "Trung vệ quét",
            },
            {
                id: "RB",
                name: "Hậu vệ biên phải",
            },
            {
                id: "LB",
                name: "Hậu vệ biên trái",
            },
            {
                id: "LWB",
                name: "Hậu vệ tấn công trái",
            },
            {
                id: "RWB",
                name: "Hậu vệ tấn công phải",
            },
            {
                id: "CB",
                name: "Trung vệ",
            },
        ],
    },
    {
        id: "tien_ve",
        label: "Hàng tiền vệ",
        values: [
            {
                id: "CM",
                name: "Tiền vệ trung tâm",
            },
            {
                id: "CDM",
                name: "Tiền vệ lùi",
            },
            {
                id: "CAM",
                name: "Tiền vệ tấn công",
            },
            {
                id: "LM",
                name: "Tiền vệ cánh trái",
            },
            {
                id: "RM",
                name: "Tiền vệ cánh phải",
            },
            {
                id: "LDM",
                name: "Tiền vệ phòng ngự lệch trái",
            },
            {
                id: "RDM",
                name: "Tiền vệ phòng ngự lệch phải",
            },
        ],
    },
    {
        id: "hau_ve",
        label: "Hàng tiền đạo",
        values: [
            {
                id: "ST",
                name: "Tiền đạo cắm",
            },
            {
                id: "CF",
                name: "Hộ công",
            },
            {
                id: "LW",
                name: "Tiền đạo cánh trái",
            },
            {
                id: "RW",
                name: "Tiền đạo cánh phải",
            },
            {
                id: "RF",
                name: "Tiền đạo lệch phải",
            },
            {
                id: "LF",
                name: "Tiền đạo lệch trái",
            },
        ],
    },
];
