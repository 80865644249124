import React, { useState } from "react";
import { Select, Button } from "antd";

const Option = Select.Option;
function FilterForMobile(p) {
    const [valueAll, setValueAll] = useState("ALL");
    const { type, filters, _clearFilterForMobile } = p;

    const _handleFilter = (value) => {
        if (value === "ALL") {
            setValueAll("ALL");
        } else {
            setValueAll(value);
        }
        p._handleFilter(value, type);
    };

    const _renderListFilter = () => {
        const filterAll = filters.filter((item) => item.value === "ALL");
        return (
            <div className="filter-select">
                {filterAll.length === 0 ? (
                    <Select
                        style={{ width: 150, marginRight: "10px" }}
                        defaultValue={valueAll}
                        value={valueAll}
                        onChange={_handleFilter}
                    >
                        <Option value="ALL">Tất cả</Option>
                        {filters &&
                            filters.map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                    </Select>
                ) : (
                    <Select
                        style={{ width: 150, marginRight: "10px" }}
                        defaultValue={valueAll}
                        value={valueAll}
                        onChange={_handleFilter}
                    >
                        {filterAll.length !== 0 &&
                            filters &&
                            filters.map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                    </Select>
                )}
                <Button
                    onClick={() => {
                        setValueAll("ALL");
                        _clearFilterForMobile(type);
                    }}
                    disabled={valueAll === "ALL" ? true : false}
                >
                    Tạo lại
                </Button>
            </div>
        );
    };
    return <React.Fragment>{_renderListFilter()}</React.Fragment>;
}

export default FilterForMobile;
